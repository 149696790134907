<template>
  <div>
    <section class="main">
      <div class="container-fluid main-box-content contentModulo pt-2 pb-4">
        <div class="breadcrumb px-3 mb-2 mb-md-3">
          <ul>
            <li>{{ translate('breadcrumb.home') }}</li>
            <li>{{ translate('breadcrumb.templates') }}</li>
            <li>{{ is_edit ? translate('breadcrumb.update') : translate('breadcrumb.create') }}</li>
          </ul>
        </div>

        <div class="title mt-2 mt-md-0 mb-3 mb-md-4">
          <h1 class="my-0 fw-700">
            {{ is_edit ? translate('title.updateTemplate') : translate('title.createTemplate') }}
          </h1>
        </div>

        <v-card class="mt-3 mt-md-4">
          <v-card-text>
            <v-form
              class="w-100"
              ref="form_template"
              method="post"
              v-model="form_valid"
              lazy-validation
              @submit.prevent="manageTemplate"
            >
              <v-text-field
                filled
                :label="translate('form.title')"
                v-model="name"
                :rules="[rules.requerido]"
              ></v-text-field>

              <!-- Editor simple pero efectivo -->
              <v-tabs v-model="activeTab" background-color="primary" dark>
                <v-tab>Editor</v-tab>
                <v-tab>HTML</v-tab>
              </v-tabs>
              
              <v-tabs-items v-model="activeTab">
                <v-tab-item>
                  <!-- Vista de edición visual -->
                  <div class="pa-4 content-preview" v-html="code"></div>
                </v-tab-item>
                <v-tab-item>
                  <!-- Vista de edición HTML -->
                  <v-textarea
                    v-model="code"
                    filled
                    auto-grow
                    rows="15"
                    class="code-editor"
                    hide-details
                  ></v-textarea>
                </v-tab-item>
              </v-tabs-items>

              <v-btn
                type="submit"
                rounded
                color="primary"
                min-width="150"
                class="d-table mx-auto mt-3"
              >
                {{ translate('button.save') }}
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </div>
    </section>
  </div>
</template>

<script>
import ManageController from "../controllers/ManageController";
import TranslationService from "@/services/translationService";

export default {
  name: "GestionPlantilla",
  components: {},
  data() {
    return {
      name: "",
      code: "",
      template_id: null,
      is_edit: false,
      activeTab: 0,
      
      // Agregamos una copia de respaldo
      _backupData: {
        name: "",
        code: ""
      },

      form_valid: true,
      rules: {
        requerido: (value) => !!value || this.translate("validation.required"),
      },

      manage_controller: null,
    };
  },

  mounted() {
    this.manage_controller = new ManageController(this);
    const templateId = this.$route.params.id;
    if (templateId) {
      this.is_edit = true;
      this.template_id = templateId;
      this.obtenerDatosPlantilla(templateId);
    }

    // Escucha cambios de idioma - SIN $forceUpdate
    TranslationService.EventBus.$on("localeChanged", () => {
      console.log("Evento localeChanged recibido en GestionPlantilla.vue");
      // NO USAR $forceUpdate() aquí
    });
  },

  beforeDestroy() {
    // Eliminar listeners para evitar memory leaks
    TranslationService.EventBus.$off("localeChanged");
  },

  watch: {
    name(newVal, oldVal) {
      console.log('nombre cambiado:', oldVal, '->', newVal);
      if (newVal && newVal !== "") {
        this._backupData.name = newVal;
      } else if (oldVal && this._backupData.name) {
        // Restaurar desde la copia de seguridad si se pierde
        this.$nextTick(() => {
          this.name = this._backupData.name;
        });
      }
    },
    code(newVal, oldVal) {
      console.log('código cambiado:', 
        oldVal?.substring(0, 20), 
        '->', 
        newVal?.substring(0, 20)
      );
      if (newVal && newVal !== "") {
        this._backupData.code = newVal;
      } else if (oldVal && this._backupData.code) {
        // Restaurar desde la copia de seguridad si se pierde
        this.$nextTick(() => {
          this.code = this._backupData.code;
        });
      }
    }
  },

  methods: {
    translate(key) {
      return TranslationService.t(key);
    },
    
    obtenerDatosPlantilla(templateId) {
      this.manage_controller
        .obtenerPlantillaPorId(templateId)
        .then((plantilla) => {
          console.log('Plantilla obtenida:', plantilla);
          
          // Guardamos en la copia de seguridad primero
          this._backupData.name = plantilla.name;
          this._backupData.code = plantilla.code;
          
          // Usamos nextTick para asegurar que la asignación sea en el siguiente ciclo
          this.$nextTick(() => {
            this.name = plantilla.name;
            this.code = plantilla.code;
          });
        })
        .catch((error) => {
          console.error("Error al obtener la plantilla:", error);
        });
    },
    
    manageTemplate() {
      // Verificar si hay datos perdidos y restaurarlos
      if (!this.name && this._backupData.name) {
        this.name = this._backupData.name;
      }
      
      if (!this.code && this._backupData.code) {
        this.code = this._backupData.code;
      }
      
      if (this.$refs.form_template.validate()) {
        this.manage_controller.manageTemplate();
      }
    },
  },
};
</script>

<style>
.content-preview {
  min-height: 400px;
  border: 1px solid #ddd;
  border-top: none;
  overflow: auto;
  background-color: white;
}

.code-editor textarea {
  font-family: monospace !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  min-height: 400px;
  border-top: none;
}

/* Estilo para el contenido visualizado */
.content-preview img {
  max-width: 100%;
  height: auto;
}

.content-preview a {
  color: #2196F3;
  text-decoration: none;
}

.content-preview a:hover {
  text-decoration: underline;
}

.content-preview h1, 
.content-preview h2, 
.content-preview h3, 
.content-preview h4, 
.content-preview h5, 
.content-preview h6 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.content-preview ul, 
.content-preview ol {
  padding-left: 2em;
}
</style>

<h1>prueba</h1>