<template>
  <div>
    <section class="main">
      <div class="container-fluid main-box-content contentModulo pt-2 pb-4">
        <!-- Breadcrumb y título se mantienen igual -->
        <div class="breadcrumb px-3 mb-2 mb-md-3">
          <ul>
            <li>{{ translate('breadcrumb.home') }}</li>
            <li>{{ translate('breadcrumb.portfolio') }}</li>
            <li>{{ translate('breadcrumb.manage') }}</li>
          </ul>
        </div>

        <div class="title mt-2 mt-md-0 mb-3 mb-md-4">
          <h1 class="my-0 fw-700">{{ translate('title.managePortfolio') }}</h1>
        </div>

        <v-card class="mt-3 mt-md-4">
          <v-card-text>
            <v-form
              class="w-100"
              ref="form_briefcase"
              method="post"
              v-model="form_valid"
              lazy-validation
              @submit.prevent="manageBriefcase"
            >
              <div class="row">
                <!-- Título en Español -->
                <div class="col-md-6">
                  <v-text-field
                    filled
                    label="Título en Español"
                    v-model="title"
                    :rules="[rules.requerido]"
                  ></v-text-field>
                </div>

                <!-- Título en Inglés -->
                <div class="col-md-6">
                  <v-text-field
                    filled
                    label="Title in English"
                    v-model="title_english"
                    :rules="[rules.requerido]"
                  ></v-text-field>
                </div>

                <!-- Input de imagen se mantiene igual -->
                <div class="col-md-6">
                  <v-file-input
                    filled
                    :label="translate('form.image')"
                    :prepend-icon="null"
                    @change="uploadFile($event)"
                    v-model="upload_photo"
                    v-if="!image"
                  ></v-file-input>

                  <div class="row pb-4" v-if="image">
                    <div class="col-md-8">
                      <img
                        :src="File.fileUrlbrief(image)"
                        class="d-block mx-auto"
                        width="90"
                        :alt="translate('form.portfolioImage')"
                      />
                    </div>
                    <div class="col-md-4">
                      <v-btn color="danger" @click="deleteImage()">
                        {{ translate('button.delete') }}
                      </v-btn>
                    </div>
                  </div>
                </div>

                <!-- URL se mantiene igual -->
                <div class="col-md-6">
                  <v-text-field
                    filled
                    :label="translate('form.url')"
                    type="url"
                    v-model="link"
                    :rules="[rules.url, rules.requerido]"
                  ></v-text-field>
                </div>

                <!-- Texto del botón en Español -->
                <div class="col-md-6">
                  <v-text-field
                    filled
                    label="Texto del Botón en Español"
                    v-model="link_name"
                    :rules="[rules.requerido]"
                  ></v-text-field>
                </div>

                <!-- Texto del botón en Inglés -->
                <div class="col-md-6">
                  <v-text-field
                    filled
                    label="Button Text in English"
                    v-model="link_name_english"
                    :rules="[rules.requerido]"
                  ></v-text-field>
                </div>

                <!-- Descripción en Español -->
                <div class="col-12">
                  <v-textarea
                    filled
                    label="Descripción en Español"
                    v-model="description"
                    :rules="[rules.requerido]"
                  ></v-textarea>
                </div>

                <!-- Descripción en Inglés -->
                <div class="col-12">
                  <v-textarea
                    filled
                    label="Description in English"
                    v-model="description_english"
                    :rules="[rules.requerido]"
                  ></v-textarea>
                </div>

                <!-- Switch de activación se mantiene igual -->
                <div class="col-md-4" v-if="is_edit">
                  <v-switch
                    v-model="is_active"
                    color="primary"
                    @change="toggleStateBriefcase()"
                    :label="translate('form.enable')"
                  ></v-switch>
                </div>
              </div>

              <v-btn
                type="submit"
                rounded
                color="primary"
                min-width="150"
                class="d-table mx-auto mt-3"
              >
                {{ translate('button.save') }}
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </div>
    </section>
  </div>
</template>

<script>
import Helpers from '../../../core/helpers/Helpers';
import File from "../../../core/helpers/File";
import ManageController from '../controllers/ManageController';
import TranslationService from "@/services/translationService";

export default {
  name: 'ManageBriefcase',

  data() {
    return {
      Helpers,
      File,
      title: '',
      title_english: '', // Nuevo campo para título en inglés
      image: '',
      upload_photo: null,
      description: '',
      description_english: '',
      is_active: false,
      is_edit: false,
      briefcase_id: null,
      link: '',
      link_name: '',
      link_name_english: '', // Nuevo campo para texto del botón en inglés
     
      form_valid: true,
      rules: {
        requerido: (value) => !!value || this.translate('validation.required'),
        url: (value) => this.validatedUrl(value) || this.translate('validation.invalidUrl'),
      },

      manage_controller: null,
    };
  },

  mounted() {
    this.manage_controller = new ManageController(this);

    TranslationService.EventBus.$on("localeChanged", () => {
      console.log("Evento localeChanged recibido en ManageBriefcase.vue");
      this.$forceUpdate();
    });
  },

  methods: {
    translate(key) {
      return TranslationService.t(key);
    },
    uploadFile(file) {
      const baseUrl = 'https://soy.esenttia.co/api-bridge.php';

      if (file) {
        File.uploadFile(file, '/briefcases', (response) => {
          if (response && response.ruta_completa) {
            let fullUrl = response.ruta_completa;
            if (!fullUrl.startsWith(baseUrl)) {
              fullUrl = `${baseUrl}${response.ruta_completa}`;
            }
            this.image = fullUrl;
            console.log("URL procesada de la imagen:", this.image);
          } else {
            console.warn("No se recibió 'ruta_completa' en la respuesta:", response);
          }
        });
      }
    },
    deleteImage() {
      this.manage_controller.deleteImage();
    },
    manageBriefcase() {
      console.log('Datos del formulario:', {
        title: this.title,
        title_english: this.title_english,
        description: this.description,
        description_english: this.description_english,
        image: this.image,
        link: this.link,
        link_name: this.link_name,
        link_name_english: this.link_name_english,
      });
      this.manage_controller.manageBriefcase();
    },
    toggleStateBriefcase() {
      this.manage_controller.toggleStateBriefcase();
    },
    validatedUrl(str) {
      let url;
      try {
        url = new URL(str);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
  },
};
</script>