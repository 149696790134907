<template>
  <div v-resize="onResize" class="panel-client">
    <div class="content-section pb-5">
      <img src="../assets/images/banner-client.jpg" width="100%" class="d-block banner-home" alt="">

      <v-bottom-navigation dark horizontal background-color="dark" color="white" class="shadow-none">
        <v-btn value="dashboard" href="#/dashboard/customer">
          <span class="fw-600 fz-16">Dashboard</span>
          <v-icon class="fz-24">mdi-view-dashboard-outline</v-icon>
        </v-btn>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn value="home" v-bind="attrs" v-on="on">
              <span class="fw-600 fz-16">Tickets</span>
              <v-icon class="fz-24">mdi-ticket-percent-outline</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item href="#/tickets/manage-customer" class="cursor-pointer">
              <v-list-item-title>Crear</v-list-item-title>
            </v-list-item>
            <v-list-item href="#/tickets/list-customer" class="cursor-pointer">
              <v-list-item-title>Ver listado</v-list-item-title>
            </v-list-item>
            <v-list-item href="#/tickets/list-trackings" class="cursor-pointer">
              <v-list-item-title>Seguimientos</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn value="update_info" v-bind="attrs" v-on="on">
              <span class="fw-600 fz-16">Actualización de datos</span>
              <v-icon class="fz-24">mdi-account-edit-outline</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title class="cursor-pointer">Información personal</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title class="cursor-pointer">Cambiar contraseña</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn value="requests" v-bind="attrs" v-on="on">
              <span class="fw-600 fz-16">Consulta de solicitudes</span>
              <v-icon class="fz-24">mdi-ballot-outline</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title class="cursor-pointer">Incapacidades</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title class="cursor-pointer">Consulta PQR</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn value="certificates" v-bind="attrs" v-on="on">
              <span class="fw-600 fz-16">Certificados</span>
              <v-icon class="fz-24">mdi-file-document-multiple-outline</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title class="cursor-pointer">Aportes y Paz y Salvo</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title class="cursor-pointer">Aislamiento</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn value="dashboard" :href="url_cerrarsession">
          <span class="fw-600 fz-16">Cerrar sesión</span>
          <v-icon class="fz-24">mdi-logout-variant</v-icon>
        </v-btn>
      </v-bottom-navigation>

      <router-view v-on:reloadpage="reloadpage" :key="idPage"></router-view>
    </div>
  </div>
</template>

<script>
import Helpers from '../helpers/Helpers';

export default {
  name: 'ClientPanel', // Cambiado de 'Client' a 'ClientPanel'
  
  data: () => ({
    Helpers,
    url_cerrarsession: '',
    windowSize: {
      x: 0,
      y: 0,
    },
    idPage: 1,
    value: 'dashboard',
    header: [
      { text: 'Nombre', value: 'lorem' },
      { text: 'Fecha', value: 'ipsum' },
      { text: '', value: 'actions' },
    ],
    dessert: [
      {
        item_id: 1,
        lorem: 'Certificado',
        ipsum: '14-05-2022',
      },
      {
        item_id: 2,
        lorem: 'Certificado',
        ipsum: '14-05-2022',
      },
      {
        item_id: 3,
        lorem: 'Certificado',
        ipsum: '14-05-2022',
      },
      {
        item_id: 4,
        lorem: 'Certificado',
        ipsum: '14-05-2022',
      },
      {
        item_id: 5,
        lorem: 'Certificado',
        ipsum: '14-05-2022',
      },
      {
        item_id: 6,
        lorem: 'Certificado',
        ipsum: '14-05-2022',
      },
    ],
  }),

  mounted() {
    this.url_cerrarsession = Helpers.urlInterna('intranet/api/crcore/cerrarsession');
  },

  methods: {
    onResize() {
      this.windowSize.x = window.innerWidth;
      this.windowSize.y = window.innerHeight;
    },

    reloadpage() {
      this.idPage += 1;
      
    },
  },
};
</script>
