import Rest from '../../../core/libs/Rest';

class ListRepository {
  rest;

  constructor() {
    this.rest = new Rest();
  }

  listBriefcases() {
    this.rest.setParams('');
    this.rest.setEndPoint('/api-bridge.php/card/briefcase/paginate-all');
    return this.rest.get();
  }

  toggleStateBriefcase(briefcase_id) {
    this.rest.setParams('');
    this.rest.setEndPoint('/api-bridge.php/card/briefcase/toogle-state-briefcase');
    return this.rest.put(briefcase_id);
  }
}

export default ListRepository;