<template>
  <div>
    <section class="main">
      <div class="container-fluid main-box-content contentModulo pt-2 pb-4">
        <div class="breadcrumb px-3 mb-2 mb-md-3">
          <ul>
            <li>{{ translate('breadcrumb.home') }}</li>
            <li>{{ translate('breadcrumb.templates') }}</li>
            <li>{{ translate('breadcrumb.list') }}</li>
          </ul>
        </div>

        <div class="title mt-2 mt-md-0 mb-3 mb-md-4">
          <h1 class="my-0 fw-700">{{ translate('title.templateList') }}</h1>
        </div>

        <v-card class="mt-3 mt-md-4">
          <v-card-text>
            <div class="row">
              <div class="col-md-8 col-lg-9 col-xl-10">
                <v-text-field
                  filled
                  v-model="search_list"
                  append-icon="mdi-magnify"
                  :label="translate('search.label')"
                  hide-details
                  clearable
                  class="my-0"
                ></v-text-field>
              </div>
              <div class="col-md-4 col-lg-3 col-xl-2">
                <v-btn
                  color="success"
                  min-height="56"
                  width="100%"
                  href="#/templates/manage"
                >
                  {{ translate('button.createTemplate') }}
                </v-btn>
              </div>
            </div>

            <div class="table-responsive mt-3 mt-md-4">
              <v-data-table
                class="my-0"
                :headers="headers"
                :items="lists"
                :items-per-page="10"
                :search="search_list"
              >
                <template v-slot:item.name="{ item }">
                  {{ item.name }}
                </template>
                <template v-slot:item.created_at="{ item }">
                  {{ formatDate(item.created_at) }}
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="d-flex align-items-center justify-content-center flex-nowrap">
                    <!-- Detalle -->
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="success"
                          v-bind="attrs"
                          v-on="on"
                          @click="openDetail(item.template_id)"
                        >
                          <v-icon>mdi-eye-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ translate('button.viewDetail') }}</span>
                    </v-tooltip>

                    <!-- Editar -->
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          :href="'#/templates/manage/' + item.template_id"
                        >
                          <v-icon>mdi-pencil-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ translate('button.edit') }}</span>
                    </v-tooltip>

                    <!-- Eliminar -->
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="danger"
                          v-bind="attrs"
                          v-on="on"
                          @click="deleteTemplate(item.template_id)"
                        >
                          <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ translate('button.delete') }}</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-card-text>
        </v-card>

        <v-dialog v-model="showDetailDialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ translate('dialog.templateDetails') }}</span>
            </v-card-title>
            <v-card-text>
              <v-container v-if="selectedTemplate">
                <v-row>
                  <v-col cols="12">
                    <strong>{{ translate('dialog.name') }}:</strong>
                    {{ selectedTemplate.name }}
                  </v-col>
                  <v-col cols="12">
                    <strong>{{ translate('dialog.code') }}:</strong>
                    <div v-html="selectedTemplate.code"></div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDetail">
                {{ translate('button.close') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </section>
  </div>
</template>

<script>
import Helpers from "../../../core/helpers/Helpers";
import ListController from "../controllers/ListController";
import TranslationService from "@/services/translationService";

export default {
  name: "ListaPlantillas",
  data() {
    return {
      Helpers,
      headers: [],
      lists: [],
      search_list: "",
      dialog_detail: {},
      showDetailDialog: false,
      selectedTemplate: null,
      list_controller: null,
    };
  },

  mounted() {
    this.list_controller = new ListController(this);
    this.loadTemplates();

    // Escucha cambios de idioma
    TranslationService.EventBus.$on("localeChanged", () => {
      console.log("Evento localeChanged recibido en List.vue");
      this.$forceUpdate();
    });
  },

  methods: {
    translate(key) {
      return TranslationService.t(key);
    },
    loadTemplates() {
      this.list_controller
        .loadTemplates()
        .then(() => {
          console.log("Plantillas cargadas con éxito");
        })
        .catch((error) => {
          console.error("Error al cargar las plantillas:", error);
        });
    },
    openDetail(template_id) {
      console.log("openDetail llamado con template_id:", template_id);
      this.list_controller.openDetail(template_id);
    },
    closeDetail() {
      this.list_controller.closeDetail();
    },
    deleteTemplate(template_id) {
      this.list_controller
        .deleteTemplate(template_id)
        .then(() => {
          console.log("Plantilla eliminada con éxito");
          this.loadTemplates(); // Recargar la lista después de eliminar
        })
        .catch((error) => {
          console.error("Error al eliminar la plantilla:", error);
        });
    },
    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString();
    },
  },
};
</script>
