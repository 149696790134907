import { render, staticRenderFns } from "./Manage.vue?vue&type=template&id=01b6d690"
import script from "./Manage.vue?vue&type=script&lang=js"
export * from "./Manage.vue?vue&type=script&lang=js"
import style0 from "./Manage.vue?vue&type=style&index=0&id=01b6d690&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./Manage.vue?vue&type=custom&index=0&blockType=h1"
if (typeof block0 === 'function') block0(component)

export default component.exports