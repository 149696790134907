import UpdateRepository from "../repositories/UpdateRepository";

class UpdateService {
  update_repository;

  constructor() {
    this.update_repository = new UpdateRepository();
  }

  async infoCompany() {
    let r = await this.update_repository.infoCompany();
    return r;
  }

  async updateCompany(
    photo, 
    list_address, 
    scheduled, 
    scheduled_en, // Nuevo parámetro para el horario en inglés
    website, 
    facebook, 
    instagram, 
    twitter, 
    linkedin, 
    skype, 
    email, 
    url_company,
    descriptionEs,  
    descriptionEn, 
    url1, 
    url2, 
    url3, 
    descripcion1,
    descripcion1_en, 
    descripcion2,
    descripcion2_en, 
    descripcion3,
    descripcion3_en  
  ) {
    let r = await this.update_repository.updateCompany(
      photo, 
      JSON.stringify(list_address), 
      scheduled,        // Horario en español
      scheduled_en,     // Horario en inglés
      website, 
      facebook, 
      instagram, 
      twitter, 
      linkedin, 
      skype, 
      email, 
      url_company,
      descriptionEs, 
      descriptionEn,
      url1, 
      url2, 
      url3, 
      descripcion1,
      descripcion1_en,
      descripcion2,
      descripcion2_en,
      descripcion3,
      descripcion3_en
    );
    return r;
  }
}

export default UpdateService;