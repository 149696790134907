import Vue from "vue";
import LoginLayout from "../layouts/Login.vue";
import Site from '../layouts/Principal.vue';
import Login from "../modules/login/views/Form.vue";
import Start from "../modules/start/views/Hello.vue";
import Jobs from "../modules/jobs/views/Jobs.vue";
import HomeClient from "../modules/clients/views/Home.vue";
import Client from '../layouts/Client.vue';  // Added import for Client
import ProfileMain from "../../modules/profile/views/ProfileMain.vue"
import SessionUser from "../libs/SessionUser"


//examples
import Paginator from "../modules/examples/views/Paginator.vue";
import Chart from "../modules/examples/views/Chart.vue";
Vue.component("filter-select", require("../modules/tables/paginator/views/FilterSelect.vue").default);
Vue.component("table-filters", require("../modules/tables/paginator/views/Filters.vue").default);  // Changed to multi-word name
Vue.component("table-paginator-simple", require("../modules/tables/paginator/views/TablePaginatorSimple.vue").default);
Vue.component("table-paginator-extended", require("../modules/tables/paginator/views/TablePaginatorExtended.vue").default);

Vue.component("table-paginator-view", require("../modules/tables/dynamic_views/views/TablePaginatorView.vue").default);
Vue.component("dynamic-view", require("../modules/tables/dynamic_views/views/DynamicView.vue").default);
Vue.component("filter-select-view", require("../modules/tables/dynamic_views/views/FilterSelect.vue").default);
Vue.component("filters-view", require("../modules/tables/dynamic_views/views/Filters.vue").default);

Vue.component("core-header", require("../modules/layouts/components/header.vue").default);
Vue.component("core-menu", require("../modules/menu/views/Menu.vue").default);

if(process.env.VUE_APP_CUSTOM_MENU_TOP){
  Vue.component("core-menu-superior", require('../../modules/_core/'+ process.env.VUE_APP_CUSTOM_MENU_TOP).default);
}else{
  Vue.component("core-menu-superior", require("../modules/menu-superior/views/Menu.vue").default);
}

window.routes.push({ path: "/", redirect: "/login/ingresar" });

window.routes.push({
  path: '/login',
  name: 'UserLogin',
  component: LoginLayout,
  children: [
    { path: 'ingresar', 
      name: 'ingresar',
      component: Login 
    }
  ],
});

window.routes.push({
  path: '/start',
  name: 'start',
  component: Site,
  children: [
    { path: 'welcome', 
      name: 'welcome',
      component: Start 
    }
  ],
});

window.routes.push({
  path: '/client',
  name: 'clientPanel',
  component: Client,
  children: [
    { path: 'home', 
      name: 'home',
      component: HomeClient 
    }
  ],
});

// window.routes.push({
//   path: '/developer',
//   name: 'developer',
//   component: Site,
//   children: [
//     { path: 'jobs', 
//       name: 'jobs',
//       component: Jobs 
//     }
//   ],
// });

window.routes.push({
  path: '/files',
  name: 'files',
  component: Site,
  children: [
    { path: 'loadfile', 
      name: 'loadfile',
      component: Jobs 
    }
  ],
});

window.routes.push({
  path: '/examples',
  name: 'examples',
  component: Site,
  children: [
    { path: 'paginator', 
      name: 'paginator',
      component: Paginator 
    },
    { path: 'chart', 
      name: 'chart',
      component: Chart
    }
  ],
});
window.routes.push({
  path: '/profile/:userId',
  name: 'userProfile',
  component: Site,  // Asume que quieres usar el layout principal
  children: [
    { 
      path: '',
      name: 'profileMain',
      component: ProfileMain
    }
  ],
});

loadMenu();

function loadMenu() {
  try {
    const sessionUser = new SessionUser();
    const userRole = sessionUser.getTypeUser(); // Si es asíncrona, usa await
    console.log('User Role:', userRole);

    let menu = require('../config/menu.json');
    let menu_core;

    /* if (userRole === 'adm') {
      console.log('Loading admin menu');
      menu_core = require('../../modules/_core/menu.json');
    } else {
      console.log('Loading auxiliary menu');
      menu_core = require('../../modules/_core/menu_auxiliar.json');
    }

    for (const item of menu_core) {
      menu.push(item);
    } */

    window.menu = menu;
    console.log('Final menu:', window.menu);
  } catch (e) {
    console.error("Error loading menu:", e);
  }
}
