import UI from '../../../core/helpers/UI';
import ProfileService from '../services/ProfileService';
import File from "../../../core/helpers/File";

class ProfileController {
  context;
  profile_service;

  constructor(context) {
    this.context = context;
    this.profile_service = new ProfileService();

    if(this.context.$route.params.id) {
      this.context.contact_id = parseInt(this.context.$route.params.id);
    }
    
    this._infoProfile(this.context.contact_id);
    this._cardAddContact(this.context.contact_id);
  }

  _infoProfile(contact_id) {
    this.context.list_address = [];
    UI.loading('show');
    this.profile_service.infoProfile(contact_id)
    .then((r) => {
      if(r.success == true) {
        let first_name = r.data.pri_nombre != null && r.data.pri_nombre != 'null' ? r.data.pri_nombre + ' ' : '';
        let second_name = r.data.seg_nombre != null && r.data.seg_nombre != 'null' ? r.data.seg_nombre + ' ' : '';
        let first_surname = r.data.pri_apellido != null && r.data.pri_apellido != 'null' ? r.data.pri_apellido + ' ' : '';
        let second_surname = r.data.seg_apellido != null && r.data.seg_apellido != 'null' ? r.data.seg_apellido : '';

        this.context.contact_id = r.data.usuario_id;
        const _photo = r.data.foto;
        this.context.photo = _photo;

        this.context.names = first_name + second_name;
        this.context.surnames = first_surname + second_surname;
        
        // Guardamos ambas versiones del cargo
        this.context.position = r.data.name_position;
        this.context.positionTranslations = {
          es: r.data.name_position || '',
          en: r.data.name_position_en || r.data.name_position // Fallback al español si no hay traducción
        };

        this.context.phone_number = r.data.telefono;
        this.context.address = r.data.address;
        this.context.email = r.data.email;
        this.context.website = r.data.website;
        this.context.facebook = r.data.facebook;
        this.context.instagram = r.data.instagram;
        this.context.twitter = r.data.twitter;
        this.context.linkedin = r.data.linkedin;
        this.context.skype = r.data.skype;

        // Modificamos el template para insertar el cargo
        let template = r.data.code_template;
        if (template) {
          // Buscamos el elemento h3 con la clase position-custom
          const positionRegex = /<h3[^>]*class="[^"]*position-custom[^"]*"[^>]*>(.*?)<\/h3>/;
          
          // Reemplazamos el contenido entre las etiquetas h3
          template = template.replace(
            positionRegex,
            `<h3 class="cl_naranja pacifico fw-900 mb-4 position-custom" style="color: #ff8200 !important; text-align: center !important; font-size: 18px !important;"><span data-position-translation>${this.context.positionTranslations[this.context.currentLanguage]}</span></h3>`
          );
        }
        this.context.template = template;
        
        if(r.data.qr_code && r.data.qr_code != null && r.data.qr_code != 'null') {
          this.context.qr_code = r.data.qr_code;
        }
        
        // Después de asignar el template, actualizamos el cargo según el idioma actual
        this._updatePositionInTemplate();
        
        UI.loading('hide');
      }
    }).catch((e) => {
      UI.loading('hide');
      UI.mensajeBad('', e.msg);
    });
  }

  _updatePositionInTemplate() {
    // Este método se puede llamar después de cargar el template y cuando cambie el idioma
    if (this.context && this.context.$el) {
      const currentPosition = this.context.currentLanguage === 'en' 
        ? this.context.positionTranslations.en 
        : this.context.positionTranslations.es;

      // Actualizamos todos los elementos que contienen el cargo
      const positionElements = this.context.$el.querySelectorAll('[data-position-translation]');
      positionElements.forEach(element => {
        element.textContent = currentPosition;
      });
    }
  }

  updatePosition(newLanguage) {
    if (this.context.template) {
      const position = this.context.positionTranslations[newLanguage];
      
      // Actualizamos el template con el nuevo cargo
      let updatedTemplate = this.context.template;
      const positionElement = updatedTemplate.querySelector('[data-position-translation]');
      if (positionElement) {
        positionElement.textContent = position;
      }
      
      this.context.template = updatedTemplate;
      this.context.position = position;  // Actualizamos también la propiedad position
    }
}

  openQrCode() {
    this.context.$set(this.context.dialog_qr, 0 , true);
  }

  closeQrCode() {
    this.context.$set(this.context.dialog_qr, 0 , false);
  }

  _cardAddContact(contact_id) {
    UI.loading('show');
    this.profile_service.cardAddContact(contact_id)
    .then((r) => {
      if(r.success == true) {
        this.context.card_contact = '';
        this.context.card_contact = r.data.link;
        
        UI.loading('hide');
      }
    }).catch((e) => {
      this.context.error = e;
      UI.loading('hide');
      UI.mensajeBad('', e.msg);
    });
  }
}

export default ProfileController;