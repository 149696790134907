import UI from '../../../core/helpers/UI';
import ListService from '../services/ListService';
import TranslationService from "@/services/translationService";

class ListController {
  context;
  list_service;

  constructor(context) {
    this.context = context;
    this.list_service = new ListService();

    this.setupHeaders();

    TranslationService.EventBus.$on("localeChanged", () => {
      console.log("Cambio de idioma detectado en ListController");
      this.setupHeaders();
      // Actualizar los datos existentes sin hacer nueva petición
      this.updateLocalizedData();
    });

    this._listBriefcases();
  }

  updateLocalizedData() {
    if (this.context.lists && this.context.lists.length > 0) {
      this.context.lists = this.context.lists.map(item => ({
        ...item,
        localizedTitle: this.getLocalizedTitle(item),
        localizedDescription: this.getLocalizedDescription(item)
      }));
    }
  }

  setupHeaders() {
    this.context.headers = [
      { text: "", value: "checkbox" },
      { 
        text: TranslationService.t("table.headers.title") || "Título", 
        value: "localizedTitle",
        render: (item) => this.getLocalizedTitle(item)
      },
      { text: TranslationService.t("table.headers.image") || "Imagen", value: "photo" },
      { 
        text: TranslationService.t("table.headers.description") || "Descripción", 
        value: "localizedDescription",
        render: (item) => this.getLocalizedDescription(item)
      },
      { text: "", value: "actions" },
    ];
  }

  getLocalizedTitle(item) {
    const currentLocale = TranslationService.getCurrentLocale();
    return currentLocale === 'es' ? item.title : (item.title_english || item.title);
  }

  getLocalizedDescription(item) {
    const currentLocale = TranslationService.getCurrentLocale();
    const description = currentLocale === 'es' ? 
      item.description : 
      (item.description_english || item.description);
    
    return description ? description.substring(0, 300) + '...' : 
      (currentLocale === 'es' ? 'Sin descripción disponible' : 'No description available');
  }

  _listBriefcases() {
    this.context.lists = [];
    UI.loading("show");
    this.list_service
      .listBriefcases()
      .then((r) => {
        if (r.success == true) {
          // Procesar los datos para incluir campos localizados
          this.context.lists = r.data.items.map(item => ({
            ...item,
            localizedTitle: this.getLocalizedTitle(item),
            localizedDescription: this.getLocalizedDescription(item)
          }));
          UI.loading("hide");
        }
      })
      .catch((e) => {
        UI.loading("hide");
        UI.mensajeBad("", e.msg);
      });
  }

  toggleStateBriefcase(briefcase_id, position, state) {
    UI.loading("show");
    this.list_service
      .toggleStateBriefcase(briefcase_id)
      .then((r) => {
        if (r.success == true) {
          UI.mensajeOk("", r.msg);
          if (state == 1) {
            this.context.lists[position].state = 2;
          } else {
            this.context.lists[position].state = 1;
          }
          UI.loading("hide");
        }
      })
      .catch((e) => {
        UI.loading("hide");
        UI.mensajeBad("", e.msg);
      });
  }

  openDetail(briefcase_id) {
    this.context.$set(this.context.dialog_detail, briefcase_id, true);
  }

  closeDetail(briefcase_id) {
    this.context.$set(this.context.dialog_detail, briefcase_id, false);
  }
}

export default ListController;