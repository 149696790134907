import UI from  '../../../core/helpers/UI';
import File from "../../../core/helpers/File";
import UpdateService from '../services/UpdateService';

class UpdateController {
  context;
  update_service;
  valid;

  constructor(context) {
    this.context = context;
    this.update_service = new UpdateService();

    //this.addLocation();
    this.infoCompany();
  }

  infoCompany() {
    this.context.list_address = [];
    UI.loading('show');
    this.update_service.infoCompany()
    .then((r) => {
      if(r.success == true) {
        this.context.name = r.data.empresa;
        this.context.photo = r.data.image_logo;

        if(r.data.address) {
          let address = JSON.parse(r.data.address);
          this.context.list_address = address;
        }

        this.context.scheduled = r.data.schedule;
        this.context.scheduled_en = r.data.schedule_en;
        this.context.website = r.data.website;
        this.context.facebook = r.data.facebook;
        this.context.instagram = r.data.instagram;
        this.context.twitter = r.data.twitter;
        this.context.linkedin = r.data.linkedin;
        this.context.email = r.data.email;
        this.context.skype = r.data.skype;

        this.context.descriptionEs = r.data.description_es || ""; 
        this.context.descriptionEn = r.data.description_en || ""; 

        this.context.url1 = r.data.url_boton1=='null'?'':r.data.url_boton1;
        this.context.descripcion1 = r.data.descripcion_boton1=='null'?'':r.data.descripcion_boton1;
        this.context.descripcion1_en = r.data.descripcion_boton1_en=='null'?'':r.data.descripcion_boton1_en;
        
        this.context.url2 = r.data.url_boton2=='null'?'':r.data.url_boton2;
        this.context.descripcion2 = r.data.descripcion_boton2=='null'?'':r.data.descripcion_boton2;
        this.context.descripcion2_en = r.data.descripcion_boton2_en=='null'?'':r.data.descripcion_boton2_en;

        this.context.url3 = r.data.url_boton3=='null'?'':r.data.url_boton3;
        this.context.descripcion3 = r.data.descripcion_boton3=='null'?'':r.data.descripcion_boton3;
        this.context.descripcion3_en = r.data.descripcion_boton3_en=='null'?'':r.data.descripcion_boton3_en;

        UI.loading('hide');
      }
    }).catch((e) => {
      UI.loading('hide');
      UI.mensajeBad('', e.msg);
    });
}

  updateCompany() {
    this.valid = true;
  
    if (!this.context.scheduled || !this.context.scheduled_en) {
      UI.mensajeBad('', 'Debes ingresar el horario en ambos idiomas');
      this.valid = false;
    }

    if (!this.context.descriptionEs || !this.context.descriptionEn) {
      UI.mensajeBad('', 'Debes ingresar la descripción en ambos idiomas');
      this.valid = false;
    }
  
    // Validación de direcciones
    this.context.list_address.forEach((v) => {
      if(!v.address) {
        UI.mensajeBad('', 'Las ubicaciones deben llevar una dirección');
        this.valid = false;
      }
  
      if(!v.google_maps_link) {
        UI.mensajeBad('', 'Las ubicaciones deben llevar un enlace de Google Maps');
        this.valid = false;
      }
  
      if(!this.validatedUrl(v.google_maps_link)) {
        UI.mensajeBad('', "Formato de URL de enlace incorrecto. Deben incluir 'https' ó 'https'");
        this.valid = false;
      }
    });
  
    // Validación de la imagen
    if (!this.context.photo) {
      UI.mensajeBad('', 'Debes agregar una imagen de la empresa');
      this.valid = false;
    }
  
    // Validación de URLs
    if(this.context.website && !this.validatedUrl(this.context.website)) {
      UI.mensajeBad("", "Formato de URL de sitio web incorrecto. Deben incluir 'https' ó 'https'");
      this.valid = false;
    }
  
    if(this.context.facebook && !this.validatedUrl(this.context.facebook)) {
      UI.mensajeBad("", "Formato de URL de Facebook incorrecto. Deben incluir 'https' ó 'https'");
      this.valid = false;
    }
  
    if(this.context.instagram && !this.validatedUrl(this.context.instagram)) {
      UI.mensajeBad("", "Formato de URL de Instagram incorrecto. Deben incluir 'https' ó 'https'");
      this.valid = false;
    }
  
    if(this.context.twitter && !this.validatedUrl(this.context.twitter)) {
      UI.mensajeBad("", "Formato de URL de Twitter incorrecto. Deben incluir 'https' ó 'https'");
      this.valid = false;
    }
  
    if(this.context.linkedin && !this.validatedUrl(this.context.linkedin)) {
      UI.mensajeBad("", "Formato de URL de Linkedin incorrecto. Deben incluir 'https' ó 'https'");
      this.valid = false;
    }
  
    if(this.valid && this.context.$refs.form_company.validate()) {
      UI.loading('show');
  
   
  
      this.update_service.updateCompany(
        this.context.photo,
        this.context.list_address,
        this.context.scheduled,
      this.context.scheduled_en,
        this.context.website,
        this.context.facebook,
        this.context.instagram,
        this.context.twitter,
        this.context.linkedin,
        this.context.skype,
        this.context.email,
        this.context.url_company,
        this.context.descriptionEs,  
        this.context.descriptionEn, 
        this.context.url1,
        this.context.url2,
        this.context.url3,
        this.context.descripcion1,
        this.context.descripcion1_en,  
        this.context.descripcion2,
        this.context.descripcion2_en,  
        this.context.descripcion3,
        this.context.descripcion3_en 
      ).then((r) => {
        if(r.success == true) {
          UI.mensajeOk('', r.msg);
          this.valid = true;
          UI.loading('hide');
        }
      }).catch((e) => {
        UI.loading('hide');
        UI.mensajeBad('', e.msg);
      });
    }
  }

  addLocation() {
    this.context.list_address.push({
      address: '',
      google_maps_link: ''
    });
  }

  deleteLocation(position) {
    this.context.list_address.splice(position, 1);
  }
  uploadPhoto(file) {
    return new Promise((resolve, reject) => {
      if(file) {
       // console.log('Iniciando carga en el controlador:', file);
        File.uploadFile(file, '/users', (r) => {
          //console.log('Respuesta de la API:', r);
          if (r.success && r.data && r.data.rutacuenta_encode_token) {
            this.context.photo = r.data.rutacuenta_encode_token;
            //console.log('Foto actualizada:', this.context.photo);
            resolve(this.context.photo);
          } else {
            //console.error('Error en la respuesta de la API:', r);
            reject(new Error('Error al cargar la foto'));
          }
        });
      } else {
        reject(new Error('No se proporcionó ningún archivo'));
      }
    });
  }

  deletePhoto() {
    this.context.photo = '';
    this.context.upload_photo = null;
  }
  
  validatedUrl(str) {
    let url;

    try {
      url = new URL(str);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }
  
  openDialog() {
    this.closeAddress();
    this.context.$set(this.context.dialog_detail, 0, true);
  }

  closeDetail() {
    this.context.$set(this.context.dialog_detail, 0, false);
  }

  openAddress() {
    this.closeDetail();
    this.context.$set(this.context.dialog_address, 0, true);
  }

  closeAddress() {
    this.context.$set(this.context.dialog_address, 0, false);
    this.context.$set(this.context.dialog_detail, 0, true);
  }

  openGoogleMaps(google_maps) {
    window.open(google_maps, '_blank');
  }
}

export default UpdateController;