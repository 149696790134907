<template>
  <div>
    <v-bottom-navigation background-color="custom-warning" color="white" v-model="header" height="auto" max-height="inherit" class="custom-navigation" grow>
      <v-btn value="profile" min-height="inherit" height="auto" class="my-2" :href="'#/web/profile/'+contact_id">
        <span class="fw-700 fz-14 mt-1 text-white">{{ translate('navigation.profile') }}</span>
        <v-icon class="fz-34 text-white">mdi-card-account-phone-outline</v-icon>
      </v-btn>
      <v-btn value="company" min-height="inherit" height="auto" class="my-2" :href="'#/web/company/?id='+contact_id">
        <span class="fw-700 fz-14 mt-1 text-white">{{ translate('navigation.company') }}</span>
        <img src="../../_core/images/iconos.png" class="icono-family2" alt="">
      </v-btn>
      <v-btn value="contact" min-height="inherit" height="auto" class="my-2" :href="'#/web/briefcase/?id='+contact_id">
        <span class="fw-700 fz-14 mt-1 text-white">{{ translate('navigation.portfolio') }}</span>
        <v-icon class="fz-34 text-white">mdi-briefcase-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <v-btn id="language-toggle" class="language-toggle"
          style="height: 36px; width: 60px;"
          @click="toggleLanguage">
      <span class="fw-bold fz-14 text-white">{{ buttonText }}</span>
      <v-icon class="fz-30 text-white">mdi-translate</v-icon>
    </v-btn>

    <section class="p-3 mt-4 section-public section-public-profile min-vh-100">
      <img
      width="100%"
      src="../../_core/images/bg-custom-empresa.jpg"
      :class="{ 'bg-company2': true, 'expanded-bg': showFullDescription }"
    />
    
      <div class="row justify-content-center pt-3 pt-md-4 position-relative">
        <div class="col-sm-12 col-md-10 col-lg-9">
          <div class="row justify-content-around align-items-center flex-lg-column">
            <div class="col-12 mb-5" v-if="photo_brand">
              <div class="logo_ese">
                <img width="100%" class="d-block mx-auto mt-4" :src="photo_brand" />
              </div>  

              <div class="d-flex justify-content-center align-items-center mb-0 pb-5" v-if="twitter || facebook || linkedin || skype || email">
                <a :href="twitter" class="d-block px-1" target="_blank" v-if="twitter">
                  <img max-width="32" width="32" height="32" class="d-block mx-auto" src="../../_core/images/twitter.png" />
                </a>
                <a :href="facebook" class="d-block px-1" target="_blank" v-if="facebook">
                  <img max-width="32" width="32" height="32" class="d-block mx-auto" src="../../_core/images/facebook.png" />
                </a>
                <a :href="linkedin" class="d-block px-1" target="_blank" v-if="linkedin">
                  <img max-width="32" width="32" height="32" class="d-block mx-auto" src="../../_core/images/linkedin.png" />
                </a>
                <a :href="skype" class="d-block px-1" target="_blank" v-if="skype">
                  <img max-width="32" width="32" height="32" class="d-block mx-auto" src="../../_core/images/Youtube.png" />
                </a>
                <a :href="instagram" class="d-block px-1" target="_blank" v-if="instagram">
                  <img max-width="32" width="32" height="32" class="d-block mx-auto" src="../../_core/images/Insta.png" />
                </a>
                <a :href="'mailto:'+email" class="d-block px-1" target="_blank" v-if="email">
                  <img max-width="32" width="32" height="32" class="d-block mx-auto" src="../../_core/images/email.png" />
                </a>
              </div>
            </div>
            
            <h3 class="text-center mb-0 mt-4 fw-600 text-white fz-18 description-box" v-if="descriptionEs || descriptionEn">
              <span :class="{ 'truncate-text': !showFullDescription }">
                {{ currentLanguage === 'en' ? descriptionEn : descriptionEs }}
              </span>
              <br>
              <v-btn small text color="white" class="mt-2" @click="toggleDescription">
                {{ showFullDescription ? translate('showLess') : translate('showMore') }}
              </v-btn>
            </h3>
            
            <div class="col-12"> 
              <h3 
              class="text-center mb-0 mt-4 fw-600 text-white fz-18" 
              v-if="scheduled || scheduled_en"
            >
              {{ translate('openingHours') }}
              <br>
              {{ translate('form.schedule') === 'Service Hours' ? scheduled_en : scheduled }}
            </h3>

              <hr class="line-white my-5" v-if="phone">

              <h3 class="text-center my-0 fw-600 text-white fz-18" v-if="phone">{{ translate('switchboard') }} {{ phone }}</h3>

              <hr class="line-white">

              <div class="d-flex align-items-center flex-wrap justify-content-center mb-3 cursor-pointer" 
              v-if="list_address.length === 1" 
              @click="openGoogleMaps(list_address[0].google_maps_link)">
           <v-icon color="#FF8201" class="w-auto h-auto fz-32">mdi-map-marker</v-icon>
           <h3 class="text-center my-0 fw-600 text-white fz-18">{{ translate('viewOnGoogleMaps') }}</h3>
         </div>

         <div class="d-flex align-items-center flex-wrap justify-content-center mb-3 cursor-pointer" 
              v-else-if="list_address.length > 1" 
              @click="openAddress">
           <v-icon color="#FF8201" class="w-auto h-auto fz-32">mdi-map-marker</v-icon>
           <h3 class="text-center my-0 fw-600 text-white fz-18">{{ translate('viewListOfAddresses') }}</h3>
         </div>

              
              <!-- Botones -->
              <div class="d-flex justify-content-center mt-4" v-if="url1">
                <v-btn color="white" target="_blank" :href="url1">
                  <span class="text-uppercase fw-600 customer-danger--text">
                    {{ translate('form.schedule') === 'Service Hours' ? descripcion1_en : descripcion1 }}
                  </span>
                </v-btn>
              </div>
              
              <div class="d-flex justify-content-center mt-4" v-if="url2">
                <v-btn color="white" target="_blank" :href="url2">
                  <span class="text-uppercase fw-600 customer-danger--text">
                    {{ translate('form.schedule') === 'Service Hours' ? descripcion2_en : descripcion2 }}
                  </span>
                </v-btn>
              </div>
              
              <div class="d-flex justify-content-center mt-4" v-if="url3">
                <v-btn color="white" target="_blank" :href="url3">
                  <span class="text-uppercase fw-600 customer-danger--text">
                    {{ translate('form.schedule') === 'Service Hours' ? descripcion3_en : descripcion3 }}
                  </span>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <v-dialog 
    v-model="showAddressDialog"
    max-width="500px"
    persistent
    content-class="address-modal"
    overlay-opacity="0.8"
  >
    <v-card class="pa-4">
      <v-card-title class="headline d-flex justify-space-between">
        <span class="text-h5">{{ translate('direcciones') }}</span>
        <v-icon @click="closeAddress">mdi-close</v-icon>
      </v-card-title>

      <v-card-text class="pt-4">
        <v-list>
          <v-list-item
            v-for="(address, index) in list_address"
            :key="index"
            @click="openGoogleMaps(address.google_maps_link)"
            class="cursor-pointer mb-2"
            hover
          >
            <v-list-item-icon>
              <v-icon color="#FF8201">mdi-map-marker</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ address.address }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
          color="primary" 
          text 
          @click="closeAddress"
        >
          {{ translate('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import Helpers from '../../../core/helpers/Helpers';
import CompanyController from '../controllers/CompanyController';
import TranslationService from '@/services/translationService';

export default {
  name: 'CompanyView',
  data() {
    return {
      Helpers,
      scheduled: '',
      header: 'company',
      list_address: [],
      showAddressDialog: false,
      photo_brand: '',
      phone: '',
      website: '',
      twitter: '',
      facebook: '',
      linkedin: '',
      skype: '',
      instagram: '',
      email: '',
      city: '',
      contact_id: null,
      url1: '',
      url2: '',
      url3: '',
      positionTranslations: {
        es: '',
        en: ''
      },
      descripcion1: '',
      descripcion2: '',
      descripcion3: '',
      currentLanguage: TranslationService.getCurrentLocale(), // Idioma inicial
      company_controller: null,
      descriptionEs: '',
      descriptionEn: '',
      showFullDescription: false, 
    };
  },
  mounted() {
    this.company_controller = new CompanyController(this);

    // Corrige la URL de la imagen al inicializar
    if (this.photo_brand && !this.photo_brand.startsWith('https://')) {
      this.photo_brand = `https://soy.esenttia.co/api-bridge.php${this.photo_brand}`;
    }

    // Escucha cambios de idioma para actualización reactiva
    TranslationService.EventBus.$on('localeChanged', (locale) => {
      console.log('Cambio de idioma detectado en CompanyView:', locale);
      this.$forceUpdate();
    });
  },
  watch: {
  dialog_address: {
    deep: true,
    handler(newVal) {
      console.log('Dialog address changed:', newVal);
    }
  }
},
  computed: {
    translate() {
      return TranslationService.t;
    },
    buttonText() {
      return this.currentLanguage === 'en' ? 'ES' : 'EN';
    }
  },

  methods: {
    toggleDescription() {
    this.showFullDescription = !this.showFullDescription;
  },
    openAddress() {
      console.log('Opening modal from component');
      this.showAddressDialog = true;
    },
    closeAddress() {
      console.log('Closing modal from component');
      this.showAddressDialog = false;
    },
    openGoogleMaps(google_maps_link) {
      if (google_maps_link) {
        window.open(google_maps_link, '_blank');
      }
    },
    toggleLanguage() {
      this.currentLanguage = this.currentLanguage === 'es' ? 'en' : 'es';
      TranslationService.setLocale(this.currentLanguage);
      
      // Actualizar el cargo en el template
      if (this.profile_controller) {
        const position = this.positionTranslations[this.currentLanguage];
        const positionElement = this.$el.querySelector('[data-position-translation]');
        if (positionElement) {
          positionElement.textContent = position;
        }
      }

      this.$emit('languageChanged', this.currentLanguage);
    }
  },

  watch: {
    showAddressDialog(newVal) {
      console.log('Dialog state changed:', newVal);
    }
  }
};
</script>
<style scoped>
.address-modal {
  z-index: 9999 !important;
  position: relative;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
<style>
/* Ajuste del botón flotante */
#language-toggle {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1050;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #ff8201; 
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}
#language-toggle:hover {
  background-color: #0056b3;
}
.theme--light.v-btn.v-btn--has-bg {
  background-color: #ff8201;
}
.description-box {
  max-width: 600px; /* Ajusta según sea necesario */
  margin: 0 auto;
}

.truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Muestra solo 2 líneas */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.mt-4, .my-4 {
  margin-top: 2.5rem !important;
}

/* 🔹 Estilos generales */
img[src="../../_core/images/bg-custom-empresa.jpg"] {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
}

/* 🔹 Estilos generales */
.bg-company2 {
  transition: height 0.3s ease-in-out; /* Animación suave */
}

/* 🔹 Pantallas grandes (hasta 1380px) */
@media (max-width: 1380px) {
  .bg-company2 {
    height: 96% !important;
    object-position: center;
  }
  .bg-company2.expanded-bg {
    height: 120% !important;
  }
}

/* 🔹 Pantallas grandes (hasta 1360px) */
@media (max-width: 1360px) {
  .bg-company2 {
    height: 140% !important;
    object-position: center;
  }
  .bg-company2.expanded-bg {
    height: 160% !important;
  }
}

/* 🔹 Pantallas medianas (hasta 1280px) */
@media (max-width: 1280px) {
  .bg-company2 {
    height: 124% !important;
    object-position: center;
  }
  .bg-company2.expanded-bg {
    height: 144% !important;
  }
}

@media (max-width: 1024px ) {
  .bg-company2 {
    height: 118% !important;
    object-position: center;
  }
  .bg-company2.expanded-bg {
    height: 139% !important;
  }
}

/* 🔹 Pantallas pequeñas (hasta 920px) */
@media  (max-width: 920px) {
  .bg-company2 {
    height: 145% !important;
    object-position: center;
  }
  .bg-company2.expanded-bg {
    height: 170% !important;
  }
}

@media  (max-width: 912px) {
  .bg-company2 {
    height: 151% !important;
    object-position: center;
  }
  .bg-company2.expanded-bg {
    height: 151% !important;
  }
}

@media  (max-width: 853px) {
  .bg-company2 {
    height: 148% !important;
    object-position: center;
  }
  .bg-company2.expanded-bg {
    height: 148% !important;
  }
}

/* 🔹 Pantallas pequeñas (hasta 920px) */
@media (max-width: 820px) {
  .bg-company2 {
    height: 145% !important;
    object-position: center;
  }
  .bg-company2.expanded-bg {
    height: 145% !important;
  }
}

/* 🔹 Dispositivos pequeños (hasta 768px, como iPad Mini) */
@media (max-width: 768px) {
  .bg-company2 {
    height: 141% !important;
    object-position: center;
  }
  .bg-company2.expanded-bg {
    height: 148% !important;
  }
}

/* 🔹 Dispositivos más pequeños (hasta 700px) */
@media (min-width: 540px) and (max-width: 700px) {
  .bg-company2 {
    height: 129% !important;
    object-position: center;
  }
  .bg-company2.expanded-bg {
    height: 145% !important;
  }
}

/* 🔹 Ajuste final para móviles muy pequeños (hasta 375px) */
@media (min-width: 412px) and (min-width: 414px) and (max-width: 430px) {
  .bg-company2 {
    height: 139% !important;
    object-position: center;
  }
  .bg-company2.expanded-bg {
    height: 147% !important;
  }
}



@media (max-width: 390px) {
  .bg-company2 {
    height: 139% !important;
    object-position: center;
  }
  .bg-company2.expanded-bg {
    height: 150% !important;
  }
}


@media (max-width: 375px) {
  .bg-company2 {
    height: 132% !important;
    object-position: center;
  }
  .bg-company2.expanded-bg {
    height: 151% !important;
  }
}

@media (max-width: 344px) {
  .bg-company2 {
    height: 143% !important;
    object-position: center;
  }
  .bg-company2.expanded-bg {
    height: 153% !important;
  }
}

</style>