import Helpers from "./Helpers";

export default class Enviroment {
    static getUrlEndpoint() {
        return process.env.VUE_APP_URL_ENDPOINT;
    }

    static getUrlZfiles() {
        if (!Helpers.isEmpty(window.ENVIROMENT?.URL_ZFILES)) {
            return window.ENVIROMENT.URL_ZFILES;
        } else if (!Helpers.isEmpty(process.env.VUE_APP_URL_ZFILES)) {
            return process.env.VUE_APP_URL_ZFILES;
        } else {
            return Enviroment.getFullUrlApi() + 'zfiles/';
        }
    }
    static getLoginEndpoint() {
        return process.env.VUE_APP_LOGIN_ENDPOINT || 'https://soy.esenttia.co/api-bridge.php/login-ingresar';
    }
    
    static getUrlDashboard() {
        return !Helpers.isEmpty(window.ENVIROMENT?.URL_DASHBOARD)
            ? window.ENVIROMENT.URL_DASHBOARD
            : '/start/welcome';
    }

    static getUrlMockcup() {
        return process.env.VUE_APP_URL_MOCKUP;
    }

    static getEnvVariable(variableName, defaultValue = null) {
        try {
            return process.env[variableName] ?? defaultValue;
        } catch (e) {
            console.error(`Error al obtener ${variableName}:`, e);
            return defaultValue;
        }
    }

    static getModeDevelopment() {
        return this.getEnvVariable('VUE_APP_MODE_DEVELOPMENT');
    }

    static getTypeToken() {
        return this.getEnvVariable('VUE_APP_TYPE_TOKEN');
    }

    static getTokenAccount() {
        return this.getEnvVariable('VUE_APP_TOKEN_ACCOUNT');
    }

    static getSegmentApi() {
        return this.getEnvVariable('VUE_APP_SEGMENT_API');
    }

    static getFullUrlApi() {
        const endpoint = this.getEnvVariable('VUE_APP_URL_ENDPOINT');
        const segment = this.getEnvVariable('VUE_APP_SEGMENT_API');

        return endpoint && segment ? endpoint + segment : null;
    }

    static getTokenMinutesExpires() {
        return this.getEnvVariable('VUE_APP_TOKEN_MINUTES_EXPIRES');
    }

    static getBackArquitecture() {
        return this.getEnvVariable('VUE_APP_ARC');
    }

    static getTipoMenu() {
        return this.getEnvVariable('VUE_APP_TYPE_MENU');
    }

    static getBaseUrlEndpoint() {
        return this.getEnvVariable('VUE_APP_URL_BASE_ENDPOINT');
    }

    static getAppEnv() {
        return this.getEnvVariable('VUE_APP_ENV');
    }

    static getExternalLoadFile() {
        return this.getEnvVariable('VUE_APP_EXTERNAL_LOAD_FILE');
    }

    static getExternalBaseUrl() {
        return this.getEnvVariable('VUE_APP_EXTERNAL_BASE_URL');
    }

    static getExternalUrlLoadFile() {
        const baseUrl = this.getExternalBaseUrl();
        return baseUrl ? baseUrl + 'api-bridge.php/archivos-cargar' : null;
    }

    static isProyectOM() {
        return this.getEnvVariable('VUE_APP_IS_PROJECT_OM', false);
    }

    static getBaseUrlApic() {
        return this.getEnvVariable('VUE_APP_URL_BASE_APIC');
    }

    static getDisableCss() {
        return this.getEnvVariable('VUE_APP_DISABLE_CSS');
    }

    static getBaseUrl() {
        return this.getEnvVariable('VUE_APP_BASE_URL');
    }
}