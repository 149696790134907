import Rest from '../../../core/libs/Rest';
// import { PROFILE_MOCKUP } from '../mockups/Profile';

class ProfileRepository {
  rest;

  constructor() {
    this.rest = new Rest();
  }

  infoProfile(contact_id) {
    this.rest.setParams('/'+contact_id);
    this.rest.setEndPoint('/api-bridge.php/card/public/show-profile');
    return this.rest.get();
  }

  cardAddContact(contact_id) {
    this.rest.setParams('/'+contact_id);
    this.rest.setEndPoint('/api-bridge.php/card/public/generate-vcf');
    return this.rest.get();
  }
}

export default ProfileRepository;