import Rest from '../../../core/libs/Rest';
import { MAIN_MOCKUP } from '../mockups/Main';

class MainRepository {
  rest;

  constructor() {
    this.rest = new Rest();
  }

  infoProfile(userId) {
    console.log('MainRepository.infoProfile llamado con userId:', userId);
    this.rest.setParams('');
    this.rest.setEndPoint(`/api-bridge.php/card/contact/show-contact/${userId}`);
  
    console.log('MainRepository.infoProfile - Endpoint:', this.rest.endPoint);
    return this.rest.get();
  }
}

export default MainRepository;