<template>
  <div>
    <div class="language-toggle d-flex justify-content-end">
      <button class="btn custom-btn mx-1" @click="toggleLanguage">
        {{ buttonText }}
      </button>
    </div>
  </div>
</template>


<script>
import TranslationService from "@/services/translationService";

export default {
  name: "CustomMenu",
  data() {
    return {
      currentLanguage: TranslationService.getCurrentLocale(), // Obtiene el idioma inicial
    };
  },
  computed: {
    buttonText() {
      return this.currentLanguage === "en" ? "ENGLISH" : "ESPAÑOL";
    },
  },
  methods: {
    toggleLanguage() {
      this.currentLanguage = this.currentLanguage === "en" ? "es" : "en";
      TranslationService.setLocale(this.currentLanguage);
      this.$emit("languageChanged", this.currentLanguage); // Emitir evento si es necesario
    },
  },
};
</script>

<style>
.custom-btn {
  background: #44d7b6;
  margin-top: 14px;
  font-size: 11px;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
}

.custom-btn:hover {
  background: #3ac2a4;
  color: #ffffff;
  cursor: pointer;
}
</style>

