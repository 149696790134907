<template>
  <div>

    <!-- Barra de navegación inferior -->
    <v-bottom-navigation background-color="custom-warning" color="white" v-model="header" height="auto" max-height="inherit" class="custom-navigation" grow>
      <v-btn value="profile" min-height="inherit" height="auto" class="my-2" :href="'#/web/profile/'+contact_id">
        <span class="fw-700 fz-14 mt-1 text-white">{{ translate('navigation.profile') }}</span>
        <v-icon class="fz-34 text-white">mdi-card-account-phone-outline</v-icon>
      </v-btn>

      <v-btn value="company" min-height="inherit" height="auto" class="my-2" :href="'#/web/company/?id='+contact_id">
        <span class="fw-700 fz-14 mt-1 text-white">{{ translate('navigation.company') }}</span>
        <img src="../../_core/images/iconos.png" class="icono-family2" alt="">
      </v-btn>

      <v-btn value="contact" min-height="inherit" height="auto" class="my-2" :href="'#/web/briefcase/?id='+contact_id">
        <span class="fw-700 fz-14 mt-1 text-white">{{ translate('navigation.portfolio') }}</span>
        <v-icon class="fz-34 text-white">mdi-briefcase-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <!-- boton de añadir contacto -->
    <div class="add-contact-custom" @click="downloadContact">
      <div class="v-icon notranslate mdi mdi-account-plus theme--light text-white" aria-hidden="true"></div>
      <span class="text-white">Añadir a contactos</span>
    </div>

    <!-- Botón flotante de cambio de idioma -->
    <v-btn id="language-toggle" class="language-toggle"
          style="height: 36px; width: 60px;"
          @click="toggleLanguage">
      <span class="fw-bold fz-14 text-white">{{ buttonText }}</span>
      <v-icon class="fz-30 text-white">mdi-translate</v-icon>
    </v-btn>

    <div class="bg-page mt-5">
      <img src="../../_core/images/bg-profile.jpg" alt="">

      <section class="px-3 pt-3 pb-4 pb-md-5 section-public section-public-profile">
        <!-- Contenido de la sección pública -->
        <div class="row flex-column justify-content-center align-items-center">
          <div class="col-9 col-sm-6 col-md-5 col-lg-4 col-xl-3">
            <img max-width="200px" width="100%" class="d-block mx-auto" src="../../_core/images/logo_inicio.png" 
            style="
                margin-top: 10px;
                height: 110px;
                /* width: 303px; */
                margin-bottom: 16px;
            ">
          </div>
          <div class="mt-2 px-3" v-if="template">
            <div class="template w-100 overflow-hidden" v-html="template"></div>
          </div>
        </div>

        <div class="row justify-content-center pt-3 pt-md-4" v-if="photo && !template">
          <div class="col-12 col-md-8 col-lg-6 col-xl-4">
            <div class="photo-profile">
              <img :src="getPhotoUrl" class="d-block mx-auto rounded-circle overflow-hidden" width="150" height="150" alt="">
            </div>
          </div>
        </div>
        
        <div class="row justify-content-center pt-4">
          <div class="col-10 col-md-8" v-if="!template">
            <h1 class="my-0 text-center text-white fw-600 fz-20" v-if="names || surnames">{{ names + ' ' + surnames }}</h1>
            <h3 class="mt-2 mb-0 text-center text-dark-warning fw-600 fz-20" v-if="position">{{ position }}</h3>
            <ul class="mt-3 mx-0 p-0 list-unstyled text-center text-white fz-16 list-recorted">
              <li class="d-flex align-items-center py-1 justify-content-sm-center flex-wrap" v-if="phone_number">
                <v-icon class="mr-2 text-white">mdi-phone-outline</v-icon>
                {{ phone_number }}
              </li>
              <li class="d-flex align-items-center py-1 justify-content-sm-center flex-wrap" v-if="address">
                <v-icon class="mr-2 text-white">mdi-map-marker</v-icon>
                {{ address }}
              </li>
              <li class="d-flex align-items-center py-1 justify-content-sm-center flex-wrap" v-if="email">
                <v-icon class="mr-2 text-white" style="color: white;">mdi-email</v-icon>
                <a :href="'mailto:'+email" target="_blank">{{ email }}</a>
              </li>
              <li class="d-flex align-items-center py-1 justify-content-sm-center flex-wrap" v-if="website">
                <v-icon class="mr-2 text-white">mdi-web</v-icon>
                <a :href="website" target="_blank">{{ website }}</a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Helpers from '../../../core/helpers/Helpers';
import ProfileController from '../controllers/ProfileController';
import File from "../../../core/helpers/File";
import TranslationService from '@/services/translationService';

export default {
  name: 'ProfileView',
  data() {
    return {
      File,
      Helpers,
      header: 'company',
      contact_id: null,
      photo: '',
      names: '',
      surnames: '',
      position: '',
      positionTranslations: {
        es: '',
        en: ''
      },
      phone_number: '',
      address: '',
      email: '',
      website: '',
      facebook: '',
      instagram: '',
      twitter: '',
      linkedin: '',
      skype: '',
      qr_code: '',
      dialog_qr: {},
      template: '',
      card_contact: '',
      error: '',
      currentLanguage: TranslationService.getCurrentLocale(),
      profile_controller: null,
    };
  },

  computed: {
    buttonText() {
      return this.currentLanguage === 'en' ? 'ES' : 'EN';
    },
    getPhotoUrl() {
      // Verificar si la foto es una URL completa o solo un nombre de archivo
      if (!this.photo) return '';
      
      if (this.photo.startsWith('http://') || this.photo.startsWith('https://')) {
        // Si la URL comienza con http:// pero debería ser https://
        if (this.photo.startsWith('http://soy.esenttia.co/zfiles/')) {
          // Reemplazar con la ruta correcta
          return this.photo.replace('http://soy.esenttia.co/zfiles/', 'https://soy.esenttia.co/zfiles/archivos/users/');
        }
        // Si ya es una URL completa, devolverla como está
        return this.photo;
      } else {
        // Si es solo un nombre de archivo, agregar la ruta completa
        return `https://soy.esenttia.co/zfiles/archivos/users/${this.photo}`;
      }
    },
    currentPosition() {
      return this.positionTranslations?.[this.currentLanguage] || this.position;
    }
  },

  watch: {
    currentLanguage: {
      handler(newLanguage) {
        if (this.profile_controller && this.template) {
          // Forzamos una actualización del DOM después del cambio de idioma
          this.$nextTick(() => {
            const positionElement = this.$el.querySelector('[data-position-translation]');
            if (positionElement) {
              positionElement.textContent = this.positionTranslations[newLanguage];
            }
          });
        }
      }
    },
    template: {
      handler(newTemplate) {
        // Actualizar el cargo en el template cuando este cambie
        if (this.profile_controller) {
          this.$nextTick(() => {
            this.profile_controller._updatePositionInTemplate();
          });
        }
      }
    }
  },

  mounted() {
    console.log('Componente montado. Valor inicial de photo:', this.photo);
    this.profile_controller = new ProfileController(this);
  },

  methods: {
    downloadContact() {
    // Verificamos si hay datos disponibles
    if (!this.names || !this.phone_number || !this.email) {
      alert("Faltan datos del contacto para generar el archivo.");
      return;
    }

    // Construimos el contenido del VCF
    const vcfContent = `BEGIN:VCARD
VERSION:3.0
FN:${this.names} ${this.surnames}
N:${this.surnames};${this.names};;;
TEL;TYPE=CELL:${this.phone_number}
EMAIL:${this.email}
ORG:${this.company || ''}
ADR:;;${this.address || ''};;;
URL:${this.website || ''}
END:VCARD`;

    // Creamos el Blob con el contenido
    const blob = new Blob([vcfContent], { type: "text/vcard;charset=utf-8" });

    // Forzamos la descarga
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // Para Internet Explorer
      window.navigator.msSaveOrOpenBlob(blob, `${this.names}_${this.surnames}.vcf`);
    } else {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${this.names}_${this.surnames}.vcf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }

    alert("El contacto se ha descargado correctamente.");
  },
    translate(key) {
      return TranslationService.t(key);
    },

    toggleLanguage() {
      this.currentLanguage = this.currentLanguage === 'es' ? 'en' : 'es';
      TranslationService.setLocale(this.currentLanguage);
      
      // Actualizar el cargo en el template
      if (this.profile_controller) {
        const position = this.positionTranslations[this.currentLanguage];
        const positionElement = this.$el.querySelector('[data-position-translation]');
        if (positionElement) {
          positionElement.textContent = position;
        }
      }

      this.$emit('languageChanged', this.currentLanguage);
    },

    updatePositionInTemplate() {
      if (this.template && this.$el) {
        const positionElements = this.$el.querySelectorAll('[data-position-translation]');
        positionElements.forEach(element => {
          element.textContent = this.currentPosition;
        });
      }
    },

    addContact() {
      this.profile_controller.addContact();
    },

    openQrCode() {
      this.profile_controller.openQrCode();
    },

    closeQrCode() {
      this.profile_controller.closeQrCode();
    },
  },

  beforeDestroy() {
    // Limpieza si es necesaria
    this.profile_controller = null;
  }
};
</script>
<style>
/* Ajuste del botón flotante */
#language-toggle {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1050; 
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #ff8201; 
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  margin-bottom: 50px;
}
#language-toggle:hover {
  background-color: #0056b3; 
}
.theme--light.v-btn.v-btn--has-bg {
  background-color: #ff8201;
}
.add-contact-custom {
  position: fixed;
  bottom: 20px;
  right: 20px; 
  background: none; 
  color: white !important; 
  font-size: 15px !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 4px;
  z-index: 9999; 
}

@media (max-width: 576px) {
  .section-public-profile ul li {
    justify-content: center ;
    text-align: center ;
  }
  
  .section-public-profile ul li a,
  .section-public-profile ul li span {
    text-align: left;
    word-break: break-word;
  }
}

/* 🔹 Estilos generales */
img[src="../../_core/images/bg-profile.jpg"] {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
}

/* 🔹 Pantallas grandes (hasta 1380px) */
@media (max-width: 1380px) {
  .bg-page > img {
    height: 96% !important;
    object-position: center;
  }
}

/* 🔹 Pantallas grandes (hasta 1360px) */
@media (max-width: 1360px) {
  .bg-page > img {
    height: 140% !important;
    object-position: center;
  }
}

/* 🔹 Pantallas medianas (hasta 1280px) */
@media (max-width: 1280px) {
  .bg-page > img {
    height: 95% !important;
    object-position: center;
  }
}

/* 🔹 Tablets y pantallas medianas (hasta 1024px, como iPad) */
@media (max-width: 1024px) {
  .bg-page > img {
    height: 141% !important;
    object-position: center;
  }
}
@media (max-width: 920px) {
  .bg-page > img {
    height: 145% !important;
    object-position: center;
  }
}
@media (max-width: 912px) {
  .bg-page > img {
    height: 151% !important;
    object-position: center;
  }
}
@media (max-width: 853px) {
  .bg-page > img {
    height: 148% !important;
    object-position: center;
  }
}
@media (max-width: 820px) {
  .bg-page > img {
    height: 143% !important;
    object-position: center;
  }
}
/* 🔹 Dispositivos pequeños (hasta 768px, como iPad Mini) */
@media (max-width: 768px) {
  .bg-page > img {
    height: 135% !important;
    object-position: center;
  }
}
@media (max-width: 700px) {
  .bg-page > img {
    height: 110% !important;
    object-position: center;
  }
}
@media (max-width: 540px) {
  .bg-page > img {
    height: 92% !important;
    object-position: center;
  }
}
@media (max-width: 430px) {
  .bg-page > img {
    height: 119% !important;
    object-position: center;
  }
}
@media (max-width: 414px) {
  .bg-page > img {
    height: 115% !important;
    object-position: center;
  }
}
@media (max-width: 412px) {
  .bg-page > img {
    height: 117% !important;
    object-position: center;
  }
}
/* 🔹 Ajuste final para móviles muy pequeños (hasta 375px) */
@media (max-width: 375px) {
  .bg-page > img {
    height: 98% !important;
    object-position: center;
  }
}
@media (max-width: 344px) {
  .bg-page > img {
    height: 114% !important;
    object-position: center;
  }
}
</style>
