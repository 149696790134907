import Vue from 'vue';

const EventBus = new Vue();
const translations = {
  en: {
    welcomeMessage: "Welcome to the home page.",
    start: "Home",
    description: "This is the starting point of your journey.",
    contact: "Contact",
    company: "Company",
    portfolio: "Portfolio",
    openingHours: "Opening Hours",
    switchboard: "Switchboard",
    viewOnGoogleMaps: "View on Google Maps",
    viewListOfAddresses: "View list of addresses",
    readMore: 'Read more',
    readLess: 'Read less',
  
    menu: {
      list: "List",
      esenttia: "Esenttia",
      users: "Users",
      umanage: "Manage",
      upositions: "User Positions",
      templates: "Templates",
      tlist: "List",
      tmanage: "Manage",
      portfolio: "Portfolio",
      plist: "List",
      pmanage: "Manage",
      company: "Company",
      cupdate: "Update",
      profile: "Profile",
      myProfile: "My Profile",
    },
  
    breadcrumb: {
      home: "Home",
      users: "Users",
      userList: "User List",
      create: "Create",
      update: "Update",
      positionsList: "Positions List",
      templates: "Templates",
      list: "List",
      portfolio: "Portfolio",
      manage: "Manage",
      company: "Company",
      editCompany: "Edit Company",
      profile: "Profile",
    },
    type: {
      administrator: "Administrator",
      collaborator: "Collaborator"
    },
    title: {
      userList: "User List",
      userDetail: "User Detail",
      createUser: "Create User",
      updateUser: "Update User",
      updatePassword: "Update Password",
      positionsList: "Positions List",
      createPosition: "Create Position",
      editPosition: "Edit Position",
      templateList: "Template List",
      createTemplate: "Create Template",
      updateTemplate: "Update Template",
      portfolioList: "Portfolio List",
      managePortfolio: "Manage Portfolio",
      editCompany: "Edit Company",
      myProfile: "My Profile",
      qrCode: "QR Code",
    },
  
    search: {
      label: "Search",
    },
  
    button: {
      createPortfolio: "Create Portfolio",
      viewCompanyInfo: "View Company Info",
      createUser: "Create User",
      viewProfile: "View Profile",
      deletePhoto: "Delete Photo",
      save: "Save",
      update: "Update",
      createPosition: "Create Position",
      disable: "Disable",
      enable: "Enable",
      edit: "Edit",
      delete: "Delete",
      cancel: "Cancel",
      createTemplate: "Create Template",
      viewDetail: "View Detail",
      close: "Close",
      deleteLocation: "Delete Location",
      addAnotherLocation: "Add Another Location",
      viewOnGoogleMaps: "View on Google Maps",
      viewAddressList: "View Address List",
      viewCompany: "View Company",
      editProfile: "Edit Profile",
      viewQrCode: "View QR Code",
    },
  
    user: {
      state: {
        active: "Active",
        blocked: "Blocked",
      },
      names: "First Name",
      surnames: "Last Name",
      phone: "Phone",
      email: "Email",
    },
   
    tooltip: {
      viewDetail: "View Details",
    },
  
    table: {
      headers: {
        names: "First Name",
        surnames: "Last Name",
        type: "Type",
        state: "State",
        email: "Email",
        actions: "Actions",
        title: "Title",
        code: "Code",
        image: "Image",
        description: "Description",
      },
    },
  
    form: {
      profilePhoto: "Profile Photo",
      firstName: "First Name",
      secondName: "Second Name",
      firstSurname: "First Last Name",
      secondSurname: "Second Last Name",
      documentType: "Document Type",
      identityNumber: "Identity Number",
      userType: "User Type",
      email: "Email",
      position: "Position",
      address: "Address",
      phone: "Phone",
      minChars: "Minimum 8 characters",
      password: "Password",
      confirmPassword: "Confirm Password",
      confirmPasswordHint: "Click the eye icon to show or hide the password confirmation.",
      passwordRequirements: "The password must include at least:",
      portfolioImage: "Portfolio Image",
      googleMapsLink: "Google Maps Link",
      companyName: "Company Name",
      schedule: "Service Hours",
      schedule_es: "Schedule (Spanish)",
      schedule_en: "Schedule (English)",
      facebookLink: "Facebook Link",
      instagramLink: "Instagram Link",
      twitterLink: "Twitter Link",
      linkedinLink: "LinkedIn Link",
      youtubeLink: "YouTube Link",
      buttons: "Buttons",
      url1: "URL 1",
      description1: "Description 1",
      description1_en: "Description 1 in English",
      url2: "URL 2",
      description2: "Description 2",
      description2_en: "Description 2 in English",
      url3: "URL 3",
      description3: "Description 3",
      description3_en: "Description 3 in English",
      switchboard: "Switchboard",
      type: "Type",
      phoneNumber: "Phone Number",
  
      requirement: {
        uppercase: "One uppercase letter",
        lowercase: "One lowercase letter",
        number: "One number",
        specialChar: "One special character (@, $, !, %, *, #, ?, &)",
      },
  
      positionName: "Position Name",
      positionDescription: "Position Description",
      title: "Title",
      image: "Image",
      portfolioImage: "Portfolio Image",
      url: "URL",
      buttonText: "Button Text",
      description: "Description",
      enable: "Enable",
    },
  
    validation: {
      required: "This field is required",
      invalidUrl: "Invalid URL",
      invalidEmail: "Invalid email address",
    },
  
    dialog: {
      templateDetails: "Template Details",
      name: "Name",
      code: "Code",
    },
  
    messages: {
      photoUrl: "Photo URL",
      noPhoto: "No photo uploaded",
      photoDeleted: "Photo deleted",
    },
  
    navigation: {
      profile: "Profile",
      company: "Company",
      portfolio: "Portfolio",
    },
  },
      
    es: {
      welcomeMessage: "Bienvenido a la página de inicio.",
      start: "Inicio",
      description: "Este es el punto de partida de tu viaje.",
      contact: "Contacto",
      company: "Empresa",
      portfolio: "Portafolio",
      openingHours: "Horario de atención",
      switchboard: "Conmutador",
      viewOnGoogleMaps: "Ver en Google Maps",
      viewListOfAddresses: "Ver listado de direcciones",
         readMore: 'Leer más',
    readLess: 'Leer menos',

      menu: {
        list: "Listado",
        esenttia: "Esenttia",
        users: "Usuarios",
        umanage: "Gestionar",
        upositions: "Cargos de Usuarios",
        templates: "Plantillas",
        tlist: "Listado",
        tmanage: "Gestionar",
        portfolio: "Portafolio",
        plist: "Listado",
        pmanage: "Gestionar",
        company: "Empresa",
        cupdate: "Actualizar",
        profile: "Perfil",
        myProfile: "Mi Perfil",
      },
      type: {
        administrator: "Administrador",
        collaborator: "Colaborador"
      },
      //userlist
      breadcrumb: {
        home: "Inicio",
        users: "Usuarios",
        userList: "Listado de usuarios",
        create: "Crear",
        update: "Actualizar",
        positionsList: "Listado de Cargos",
        templates: "Plantillas",
        list: "Listado",
        portfolio: "Portafolio",
        manage: "Gestionar",
        company: "Empresa",
        editCompany: "Editar empresa",
        profile: "Perfil",
        




      },
      title: {
        userList: "Listado de usuarios",
        userDetail: "Detalle del usuario",
        createUser: "Crear Usuario",
        updateUser: "Actualizar Usuario",
        updatePassword: "Actualizar Contraseña",
        positionsList: "Listado de Cargos",
        createPosition: "Crear Cargo",
        editPosition: "Editar Cargo",
        templateList: "Listado de Plantillas",
        createTemplate: "Crear Plantilla",
        updateTemplate: "Actualizar Plantilla",
        portfolioList: "Listado de Portafolio",
        managePortfolio: "Gestionar Portafolio",
        editCompany: "Editar empresa",
        myProfile: "Mi perfil",
        qrCode: "Código QR",
 




      },
      search: {
        label: "Buscar",
      },
      button: {
        createPortfolio: "Crear Portafolio",
        viewCompanyInfo: "Ver información de la empresa",
        createUser: "Crear Usuario",
        viewProfile: "Ver perfil",
        deletePhoto: "Eliminar Foto",
        save: "Guardar",
        update: "Actualizar",
        createPosition: "Crear Cargo",
        disable: "Deshabilitar",
        enable: "Habilitar",
        edit: "Editar",
        delete: "Eliminar",
        cancel: "Cancelar",
        createTemplate: "Crear Plantilla",
        viewDetail: "Ver Detalle",
        close: "Cerrar",
        deleteLocation: "Eliminar ubicación",
        addAnotherLocation: "Añadir otra ubicación",
        viewOnGoogleMaps: "Ver en Google Maps",
        viewAddressList: "Ver listado de direcciones",
        viewCompany: "Ver Empresa",
        editProfile: "Editar perfil",
        viewQrCode: "Ver código QR",
      },
      user: {
        state: {
          active: "Activo",
          blocked: "Bloqueado",
        },
        names: "Nombres",
        surnames: "Apellidos",
        phone: "Teléfono",
        email: "Correo Electrónico",
      },
      tooltip: {
        viewDetail: "Ver Detalles",
      },
      table: {
        headers: {
          names: "Nombres",
          surnames: "Apellidos",
          type: "Tipo",
          state: "Estado",
          email: "Correo Electrónico",
          actions: "Acciones",
          title: "Título",
          code: "Código",
          image: "Imagen",
          description: "Descripción",
        },
      },
      form: {
        profilePhoto: "Foto de Perfil",
        firstName: "Primer Nombre",
        secondName: "Segundo Nombre",
        firstSurname: "Primer Apellido",
        secondSurname: "Segundo Apellido",
        documentType: "Tipo de Documento",
        identityNumber: "Número de Identidad",
        userType: "Tipo de Usuario",
        email: "Correo Electrónico",
        position: "Cargo",
        address: "Dirección",
        phone: "Teléfono",
        minChars: "Mínimo 8 caracteres",
        password: "Contraseña",
        confirmPassword: "Confirmar Contraseña",
        website: "Sitio web",
        template: "Plantilla",
        password: "Contraseña",
        confirmPassword: "Confirmar Contraseña",
        confirmPasswordHint: "Haga clic en el icono del ojo para mostrar u ocultar la confirmación de contraseña.",
        passwordRequirements: "La contraseña debe incluir al menos:",
        portfolioImage: "Imagen del portafolio",
        address: "Dirección",
        googleMapsLink: "Enlace Google Maps",
        companyName: "Nombre de la empresa",
        schedule: "Horario de Atención",
        schedule_es: "Horario (Español)",
        schedule_en: "Horario (Inglés)",
        facebookLink: "Enlace Facebook",
        instagramLink: "Enlace Instagram",
        twitterLink: "Enlace Twitter",
        linkedinLink: "Enlace Linkedin",
        youtubeLink: "Enlace Youtube",
        email: "Correo electrónico",
        buttons: "Botones",
        url1: "URL 1",
        description1: "Descripción 1",
        description1_en: "Descripción 1 en inglés",
        url2: "URL 2",
        description2: "Descripción 2",
        description2_en: "Descripción 2 en inglés",
        url3: "URL 3",
        description3: "Descripción 3",
        description3_en: "Descripción 3 en inglés",
        switchboard: "Conmutador",
        type: "Tipo",
        phoneNumber: "Teléfono",

        requirement: {
            uppercase: "Una letra mayúscula",
            lowercase: "Una letra minúscula",
            number: "Un número",
            specialChar: "Un carácter especial (@, $, !, %, *, #, ?, &)",
      },
      positionName: "Nombre del Cargo",
      positionDescription: "Descripción del Cargo",
      title: "Título",
      image: "Imagen",
      portfolioImage: "Imagen del Portafolio",
      url: "URL",
      buttonText: "Texto del Botón",
      description: "Descripción",
      enable: "Habilitar",

      },
      validation: {
        required: "Este campo es obligatorio",
        invalidUrl: "URL no válida",
        invalidEmail: "Correo electrónico no válido",

      },
      dialog: {
        templateDetails: "Detalles de la Plantilla",
        name: "Nombre",
        code: "Código",
      },
      messages: {
        photoUrl: "URL de la foto",
        noPhoto: "No hay foto cargada",
        photoDeleted: "Foto eliminada",
      },
      navigation: {
        profile: "Contacto",
        company: "Empresa",
        portfolio: "Portafolio",
      },
      
    
    },
  };
  
  
  let currentLocale = "en";
  
  
  const setLocale = (locale) => {
    console.log(`Cambiando idioma a: ${locale}`);
    currentLocale = locale;
    EventBus.$emit('localeChanged', locale); // Verifica si se emite el evento
    console.log('Evento localeChanged emitido'); // Confirmar que el evento fue disparado
  };
  
  
  
  const getCurrentLocale = () => {
    return currentLocale;
  };
  
  const t = (key) => {
    const keys = key.split(".");
    let value = translations[currentLocale];
    keys.forEach((k) => {
      value = value ? value[k] : key;
    });
    return value || key;
  };
  
  export default {
    setLocale,
    getCurrentLocale,
    t,
    EventBus,
    translations,
  };
  