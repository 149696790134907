import Rest from '../../../core/libs/Rest';
// Se ha eliminado la siguiente línea:
// import { UPDATE_MOCKUP } from '../mockups/Update';

class UpdateRepository {
  rest;

  constructor() {
    this.rest = new Rest();
  }

  infoCompany() {
    this.rest.setParams('');
    this.rest.setEndPoint('/api-bridge.php/card/company/show-company');
    return this.rest.get();
  }

  updateCompany(
    photo, 
    list_address, 
    scheduled, 
    scheduled_en,
    website, 
    facebook, 
    instagram, 
    twitter, 
    linkedin, 
    skype, 
    email, 
    url_company, 
	  descriptionEs, 
    descriptionEn,
    url1, 
    url2, 
    url3, 
    descripcion1,
    descripcion1_en, // Nueva descripción 1 en inglés
    descripcion2,
    descripcion2_en, // Nueva descripción 2 en inglés
    descripcion3,
    descripcion3_en  // Nueva descripción 3 en inglés
) {
    this.rest.setParams({
        image_logo     : photo, 
        address        : list_address, 
        schedule       : scheduled,
        schedule_en    : scheduled_en,
        website        : website, 
        facebook       : facebook, 
        instagram      : instagram, 
        twitter        : twitter, 
        linkedin       : linkedin, 
        skype          : skype, 
        email          : email, 
        url_company    : url_company,
		    description_es : descriptionEs,
        description_en : descriptionEn,
        url1           : url1, 
        url2           : url2, 
        url3           : url3, 
        descripcion1   : descripcion1,
        descripcion1_en: descripcion1_en,
        descripcion2   : descripcion2,
        descripcion2_en: descripcion2_en,
        descripcion3   : descripcion3,
        descripcion3_en: descripcion3_en
    });
    this.rest.setEndPoint('/api-bridge.php/card/company/update-company');
    return this.rest.put();
}
}

export default UpdateRepository;