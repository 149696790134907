<!-- eslint-disable-next-line vue/multi-word-component-names -->

<template>
  <div>
    <section class="main">
      <div class="container-fluid main-box-content contentModulo pt-2 pb-4">
        <div class="breadcrumb px-3 mb-2 mb-md-3">
          <ul>
            <li>{{ translate('breadcrumb.home') }}</li>
            <li>{{ translate('breadcrumb.profile') }}</li>
          </ul>
        </div>

        <div class="title mt-2 mt-md-0 mb-3 mb-md-4">
          <h1 class="my-0 fw-700">{{ translate('title.myProfile') }}</h1>
        </div>

        <v-card class="mt-3 mt-md-4">
          <v-card-text :class="!template ? 'pb-0 px-md-4 pt-md-4' : 'px-md-4 py-md-4'">
            <div class="row" v-if="!template">
              <div class="col-md-4 col-lg-3 mb-4">
                <img :src="photo" class="d-block mx-auto mb-3" width="100%" :alt="translate('form.profilePhoto')" v-if="photo">
                <h4 class="fw-700 fz-26 text-center my-0 text-danger">{{ names + ' ' + surnames }}</h4>

                <div class="d-flex justify-content-center mt-4">
                  <v-btn type="button" color="primary" href="#/users/manage">
                    {{ translate('button.editProfile') }}
                  </v-btn>
                </div>
              </div>
              <div class="col-md-8 col-lg-9">
                <div class="row">
                  <div class="col-md-4 mb-4 mb-md-5" v-if="position">
                    <h4 class="mt-0 mb-1 text-left text-standar fw-600 fz-18">{{ translate('form.position') }}:</h4>
                    <p class="my-0 fw-400 fz-16 text-black">{{ position }}</p>
                  </div>
                  <div class="col-md-4 mb-4 mb-md-5" v-if="type">
                    <h4 class="mt-0 mb-1 text-left text-standar fw-600 fz-18">{{ translate('form.type') }}:</h4>
                    <p class="my-0 fw-400 fz-16 text-black">{{ type }}</p>
                  </div>
                  <div class="col-md-4 mb-4 mb-md-5" v-if="phone_number">
                    <h4 class="mt-0 mb-1 text-left text-standar fw-600 fz-18">{{ translate('form.phoneNumber') }}:</h4>
                    <p class="my-0 fw-400 fz-16 text-black">{{ phone_number }}</p>
                  </div>
                  <div class="col-md-4 mb-4 mb-md-5" v-if="email">
                    <h4 class="mt-0 mb-1 text-left text-standar fw-600 fz-18">{{ translate('form.email') }}:</h4>
                    <p class="my-0 fw-400 fz-16 text-black">{{ email }}</p>
                  </div>
                  <div class="col-12 mb-4 mb-md-5" v-if="qr_code">
                    <v-btn color="primary" max-width="160" class="d-flex fw-600 rounded" @click="openQrCode()">
                      <v-icon class="mr-2">mdi-qrcode</v-icon>
                      {{ translate('button.viewQrCode') }}
                    </v-btn>

                    <v-dialog v-model="dialog_qr[0]" width="290">
                      <v-card>
                        <div class="title d-flex align-items-center justify-content-between flex-wrap p-4">
                          <h1 class="my-0">{{ translate('title.qrCode') }}</h1>
                          <v-btn icon color="danger" @click="closeQrCode()">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </div>

                        <v-card-text class="scroll-modal-quotations">
                          <div v-html="qr_code" class="qr-code"></div>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </div>
                  <div class="col-12 mb-4">
                    <ul class="mx-0 my-0 p-0 list-unstyled d-flex align-items-center justify-content-start flex-wrap">
                      <li class="mx-2 mb-3" v-if="website">
                        <v-btn icon outlined large color="success" target="_blank" :href="website">
                          <v-icon>mdi-web</v-icon>
                        </v-btn>
                      </li>
                      <li class="mx-2 mb-3" v-if="twitter">
                        <v-btn icon outlined large color="success" target="_blank" :href="twitter">
                          <v-icon>mdi-twitter</v-icon>
                        </v-btn>
                      </li>
                      <li class="mx-2 mb-3" v-if="facebook">
                        <v-btn icon outlined large color="success" target="_blank" :href="facebook">
                          <v-icon>mdi-facebook</v-icon>
                        </v-btn>
                      </li>
                      <li class="mx-2 mb-3" v-if="linkedin">
                        <v-btn icon outlined large color="success" target="_blank" :href="linkedin">
                          <v-icon>mdi-linkedin</v-icon>
                        </v-btn>
                      </li>
                      <li class="mx-2 mb-3" v-if="instagram">
                        <v-btn icon outlined large color="success" target="_blank" :href="instagram">
                          <v-icon>mdi-instagram</v-icon>
                        </v-btn>
                      </li>
                      <li class="mx-2 mb-3" v-if="skype">
                        <v-btn icon outlined large color="success" target="_blank" :href="skype">
                          <v-icon>mdi-skype</v-icon>
                        </v-btn>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="template-code profile" v-if="template" v-html="template"></div>

            <v-dialog v-model="dialog_qr[0]" width="290" v-if="template">
              <v-card>
                <div class="title d-flex align-items-center justify-content-between flex-wrap p-4">
                  <h1 class="my-0">{{ translate('title.qrCode') }}</h1>

                  <v-btn icon color="danger" @click="closeQrCode()">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>

                <v-card-text class="scroll-modal-quotations">
                  <h2 class="fw-700 fz-26 text-center my-0 text-custom-danger">{{ names + ' ' + surnames }}</h2>

                  <h4 class="fw-700 fz-22 text-center mb-0 mt-2 text-custom-warning">{{ position }}</h4>

                  <div v-html="qr_code" class="qr-code"></div>
                </v-card-text>
              </v-card>
            </v-dialog>
            
            <div class="d-flex align-items-center justify-content-center mt-3 mt-lg-4" v-if="template">
              <v-btn color="primary" outlined max-width="160" class="d-flex fw-600 mx-2 rounded" @click="openQrCode()">
                <v-icon class="mr-2">mdi-qrcode</v-icon>
                {{ translate('button.viewQrCode') }}
              </v-btn>

              <v-btn type="button" class="fw-600 mx-2" color="primary" :href="'#/users/manage/' + userId">
                {{ translate('button.editProfile') }}
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </section>
  </div>
</template>


<script>
import Helpers from '../../../core/helpers/Helpers';
import MainController from '../controllers/MainController';
import File from "../../../core/helpers/File";
import SessionUser from "../../../core/libs/SessionUser";
import TranslationService from '@/services/translationService'; // Importa el servicio de traducción

export default {
  name: 'ProfileMain',
  data() {
    return {
      Helpers,
      File,
      dialog_qr: {},
      user_id: null,
      main_controller: null,
      photo: '',
      names: '',
      surnames: '',
      position: '',
      type: '',
      phone_number: '',
      email: '',
      qr_code: '',
      name_modal: '',
      website: '',
      twitter: '',
      facebook: '',
      linkedin: '',
      instagram: '',
      skype: '',
      positionTranslations: {
        es: '',
        en: ''
      },
      currentLanguage: TranslationService.getCurrentLocale(),
      detail_controller: null,
      template: '',
      isLoadingProfile: false
    };
  },

  computed: {
    fullName() {
      return `${this.names} ${this.surnames}`;
    },
    cardTextClass() {
      return this.template ? 'px-md-4 py-md-4' : 'pb-0 px-md-4 pt-md-4';
    },
    socialLinks() {
      return {
        website: this.website,
        twitter: this.twitter,
        facebook: this.facebook,
        linkedin: this.linkedin,
        instagram: this.instagram,
        skype: this.skype,
      };
    },
    currentPosition() {
      return this.positionTranslations?.[this.currentLanguage] || this.position;
    },
    displayPosition() {
    return this.positionTranslations[this.currentLanguage] || this.position;
  },
  },
  mounted() {
  // Asegurarse de que las traducciones se apliquen después del montaje inicial
  this.$nextTick(() => {
    const positionElement = this.$el.querySelector('[data-position-translation]');
    if (positionElement && this.positionTranslations) {
      positionElement.textContent = this.positionTranslations[this.currentLanguage];
    }
  });
},

  created() {
    console.log('ProfileMain created - Iniciando');
    this.main_controller = new MainController(this);

    // Intenta obtener el userId de los parámetros de la ruta
    this.userId = this.$route.params.userId || null;
    console.log('ProfileMain - userId de la ruta (sin procesar):', this.$route.params.userId);
    console.log('ProfileMain - userId de la ruta (asignado):', this.userId);

    // Si no hay userId en la ruta o es '{userId}', obtenerlo de SessionUser
    if (!this.userId || this.userId === '{userId}') {
      const sessionUser = new SessionUser();
      this.userId = sessionUser.getUserId();
      console.log('ProfileMain - userId de SessionUser:', this.userId);
    }

    // Si aún no tenemos userId, usar 'current'
    if (!this.userId || this.userId === '{userId}') {
      this.userId = 'current';
      console.log('ProfileMain - usando userId default:', this.userId);
    }

    console.log('ProfileMain - User ID final:', this.userId);
    this.loadUserProfile();

    // Escucha cambios de idioma
    TranslationService.EventBus.$on('localeChanged', () => {
    console.log('Evento localeChanged recibido en ProfileMain.vue');
    
    // Actualizar el cargo cuando cambia el idioma
    if (this.main_controller) {
      const newLanguage = TranslationService.getCurrentLocale();
      this.currentLanguage = newLanguage;
      
      // Actualizar el DOM después del cambio de idioma
      this.$nextTick(() => {
        const positionElement = this.$el.querySelector('[data-position-translation]');
        if (positionElement) {
          positionElement.textContent = this.positionTranslations[newLanguage];
          console.log('Actualizando cargo a:', this.positionTranslations[newLanguage]);
        }
      });
    }

    this.$forceUpdate();
  });
  },
  watch: {
    currentLanguage: {
    immediate: true,
    handler(newLanguage) {
      console.log('Watch currentLanguage:', newLanguage);
      if (this.template && this.positionTranslations) {
        this.$nextTick(() => {
          const positionElement = this.$el.querySelector('[data-position-translation]');
          if (positionElement) {
            const newPosition = this.positionTranslations[newLanguage];
            console.log('Actualizando posición a:', newPosition);
            positionElement.textContent = newPosition;
          }
        });
      }
    }
  },
  template: {
    handler(newTemplate) {
      if (this.main_controller) {
        this.$nextTick(() => {
          this.main_controller._updatePositionInTemplate();
        });
      }
    }
  }
},
  methods: {
    toggleLanguage() {
  const newLanguage = this.currentLanguage === 'es' ? 'en' : 'es';
  console.log('Cambiando idioma a:', newLanguage);
  
  this.currentLanguage = newLanguage;
  TranslationService.setLocale(newLanguage);
  
  // Actualizar el cargo en el DOM
  if (this.template && this.positionTranslations) {
    this.$nextTick(() => {
      const positionElement = this.$el.querySelector('[data-position-translation]');
      if (positionElement) {
        const newPosition = this.positionTranslations[newLanguage];
        console.log('Actualizando cargo a:', newPosition);
        positionElement.textContent = newPosition;
      }
    });
  }

  this.$emit('languageChanged', this.currentLanguage);
},

  updatePositionInTemplate() {
    if (this.template && this.$el) {
      const positionElements = this.$el.querySelectorAll('[data-position-translation]');
      positionElements.forEach(element => {
        element.textContent = this.currentPosition;
      });
    }
  },
    translate(key) {
      try {
        const translation = TranslationService.t(key);
        return translation;
      } catch (error) {
        console.error(`Error al traducir la clave: ${key}`, error);
        return key; // Devuelve la clave como texto por defecto
      }
    },

    openQrCode() {
      this.main_controller.openQrCode();
    },

    closeQrCode() {
      this.main_controller.closeQrCode();
    },

    loadUserProfile() {
  console.log('ProfileMain.loadUserProfile - Iniciando carga de perfil para userId:', this.userId);
  this.isLoadingProfile = true;

  if (this.main_controller) {
    this.main_controller.getProfileData(this.userId)
      .then(profileData => {
        console.log('ProfileMain.loadUserProfile - Datos del perfil recibidos:', profileData);

        // Actualiza los datos del perfil
        this.names = profileData.pri_nombre || '';
        this.surnames = (profileData.pri_apellido || '') + ' ' + (profileData.seg_apellido || '');
        
        // Guardamos ambas versiones del cargo
        this.position = profileData.name_position || '';
        this.positionTranslations = {
          es: profileData.name_position || '',
          en: profileData.name_position_en || profileData.name_position // Fallback al español
        };

        this.type = profileData.name_tipo || '';
        this.phone_number = profileData.telefono || '';
        this.email = profileData.email || '';
        this.website = profileData.website || '';
        this.twitter = profileData.twitter || '';
        this.facebook = profileData.facebook || '';
        this.linkedin = profileData.linkedin || '';
        this.instagram = profileData.instagram || '';
        this.skype = profileData.skype || '';
        this.qr_code = profileData.qr_code || '';

        // Construye la URL de la foto
        const photoUrl = profileData.foto ? 
          `https://soy.esenttia.co/zfiles/archivos/users/${profileData.foto}` : 
          'ruta/a/imagen/por/defecto.jpg';

        // Modifica el template para incluir el cargo traducible
        let template = profileData.code_template || '';
        if (template) {
          template = template.replace(/color: #fff !important;/g, 'color: #666683 !important;');
          // Reemplaza la foto
          template = template.replace(/src="[^"]*"/, `src="${photoUrl}"`);
          
          // Aseguramos que tengamos el cargo actual
          const currentPosition = this.positionTranslations[this.currentLanguage];
          console.log('Cargo actual para template:', currentPosition); // Debug

          // Reemplaza el cargo con un elemento traducible
          const positionRegex = /<h3[^>]*class="[^"]*position-custom[^"]*"[^>]*>(.*?)<\/h3>/;
          template = template.replace(
            positionRegex,
            `<h3 class="cl_naranja pacifico fw-900 mb-4 position-custom" style="color: #ff8200 !important; text-align: center !important; font-size: 18px !important;"><span data-position-translation>${currentPosition}</span></h3>`
          );
        }
        this.template = template;

        // Forzar una actualización del DOM
        this.$nextTick(() => {
          const positionElement = this.$el.querySelector('[data-position-translation]');
          if (positionElement) {
            positionElement.textContent = this.positionTranslations[this.currentLanguage];
          }
        });

        console.log('Traducciones finales:', this.positionTranslations); // Debug
        console.log('Template actualizado:', this.template);
        console.log('URL de la foto asignada:', photoUrl);
        console.log('Traducciones del cargo:', this.positionTranslations);

        return;
      })
      .catch(error => {
        console.error('ProfileMain.loadUserProfile - Error al cargar el perfil:', error);
      })
      .finally(() => {
        this.isLoadingProfile = false;
      });
  } else {
    console.error('ProfileMain.loadUserProfile - main_controller no está inicializado');
  }
},
  },
};


</script>