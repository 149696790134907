import PositionsUsersRepository from "../repositories/PositionsUsersRepository";

class PositionsUsersService {
  positions_users_repository;

  constructor() {
    this.positions_users_repository = new PositionsUsersRepository();
  }

  async listPositionsUsers() {
    let r = await this.positions_users_repository.listPositionsUsers();
    return r;
  }

  async deletePositionUser(position_id) {
    let r = await this.positions_users_repository.deletePositionUser(position_id);
    return r;
  }

  async createPositionUser(name, description, name_en, description_en) {
    let r = await this.positions_users_repository.createPositionUser(
      name, 
      description, 
      name_en, 
      description_en
    );
    return r;
  }

  async updatePositionUser(position_id, name, description, name_en, description_en) {
    let r = await this.positions_users_repository.updatePositionUser(
      position_id, 
      name, 
      description,
      name_en,
      description_en
    );
    return r;
  }
}

export default PositionsUsersService;