import Rest from '../../../core/libs/Rest';

class PositionsUsersRepository {
  rest;

  constructor() {
    this.rest = new Rest();
  }

  listPositionsUsers() {
    this.rest.setParams('');
    this.rest.setEndPoint('/api-bridge.php/card/position/paginate-all');
    return this.rest.get();
  }

  deletePositionUser(position_id) {
    console.log('Inicio Borrado');
    this.rest.setParams('');
    this.rest.setEndPoint('/api-bridge.php/card/position/delete-position');
    console.log('setea endpoing');
    return this.rest.put(position_id);
  }

  createPositionUser(name, description, name_en, description_en) {
    this.rest.setParams({
      name: name,
      description: description,
      name_en: name_en,
      description_en: description_en
    });
    this.rest.setEndPoint('https://soy.esenttia.co/api-bridge.php/card/position/store-position');
    return this.rest.post();
  }

  updatePositionUser(position_id, name, description, name_en, description_en) {
    this.rest.setParams({
      name: name,
      description: description,
      name_en: name_en,
      description_en: description_en
    });
    this.rest.setEndPoint('/api-bridge.php/card/position/update-position');
    return this.rest.put(position_id);
  }
}

export default PositionsUsersRepository;