import UI from '../../../core/helpers/UI';
import MainService from '../services/MainService';

class DetailController {
  context;
  main_service;

  constructor(context) {
    this.context = context;
    this.main_service = new MainService();
  }

  getProfileData(userId) {
    return new Promise((resolve, reject) => {
      UI.loading('show');
      this.main_service.infoProfile(userId)
        .then((r) => {
          if (r.success) {
            let template = r.data.code_template;
            // Guardamos ambas versiones del cargo
            this.context.position = r.data.name_position;
            this.context.positionTranslations = {
              es: r.data.name_position || '',
              en: r.data.name_position_en || r.data.name_position
            };

            if (template) {
              const positionRegex = /<h3[^>]*class="[^"]*position-custom[^"]*"[^>]*>(.*?)<\/h3>/;
              template = template.replace(
                positionRegex,
                `<h3 class="cl_naranja pacifico fw-900 mb-4 position-custom" style="color: #ff8200 !important; text-align: center !important; font-size: 18px !important;"><span data-position-translation>${this.context.positionTranslations[this.context.currentLanguage]}</span></h3>`
              );
              this.context.template = template;
            }

            // Actualizamos el cargo en el template
            this._updatePositionInTemplate();

            UI.loading('hide');
            resolve(r.data);
          }
        })
        .catch((error) => {
          UI.loading('hide');
          reject(error);
        });
    });
  }

  _updatePositionInTemplate() {
    if (this.context && this.context.$el) {
      const currentPosition = this.context.currentLanguage === 'en'
        ? this.context.positionTranslations.en
        : this.context.positionTranslations.es;

      const positionElements = this.context.$el.querySelectorAll('[data-position-translation]');
      positionElements.forEach(element => {
        element.textContent = currentPosition;
      });
    }
  }

  updatePosition(newLanguage) {
    if (this.context.template && this.context.positionTranslations) {
      const position = this.context.positionTranslations[newLanguage];
      
      // Actualizamos el DOM
      if (this.context.$el) {
        const positionElements = this.context.$el.querySelectorAll('[data-position-translation]');
        positionElements.forEach(element => {
          element.textContent = position;
        });
      }

      this.context.position = position;
    }
  }
  async getProfileData(userId) {
    console.log('MainController.getProfileData llamado con userId:', userId);
    try {
      const response = await this.main_service.infoProfile(userId);
      console.log('MainController.getProfileData - Respuesta de infoProfile:', response);
      if (response.success) {
        // Si no tenemos un userId específico, intentamos obtenerlo de la respuesta
        if (!userId || userId === 'current') {
          userId = response.data.usuario_id;
          console.log('MainController.getProfileData - ID obtenido de la respuesta:', userId);
        }
        return { ...response.data, userId };
      } else {
        throw new Error('No se pudo obtener la información del perfil');
      }
    } catch (error) {
      console.error('MainController.getProfileData - Error:', error);
      throw error;
    }
  }

  openQrCode() {
    this.context.$set(this.context.dialog_qr, 0 , true);
  }

  closeQrCode() {
    this.context.$set(this.context.dialog_qr, 0 , false);
  }
}

export default DetailController;