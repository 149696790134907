import UI from '../../../core/helpers/UI';
import BriefcaseService from '../services/BriefcaseService';
import TranslationService from '@/services/translationService';

class BriefcaseController {
  context;
  briefcase_service;

  constructor(context) {
    this.context = context;
    this.briefcase_service = new BriefcaseService();

    if(this.context.$route.query.id) {
      this.context.contact_id = parseInt(this.context.$route.query.id);
    }

    this._listBriefcases();

    // Escuchar cambios de idioma
    TranslationService.EventBus.$on("localeChanged", () => {
      this.updateLocalizedData();
    });
  }

  getLocalizedText(item, field) {
    const currentLocale = TranslationService.getCurrentLocale();
    if (currentLocale === 'es') {
      return item[field] || item[field + '_english'] || ''; // Fallback al inglés si no hay español
    } else {
      return item[field + '_english'] || item[field] || ''; // Fallback al español si no hay inglés
    }
  }

  updateLocalizedData() {
    if (this.context.list_briefcases && this.context.list_briefcases.length > 0) {
      this.context.list_briefcases = this.context.list_briefcases.map(item => ({
        ...item,
        localizedTitle: this.getLocalizedText(item, 'title'),
        localizedDescription: this.getLocalizedText(item, 'description'),
        localizedLinkName: this.getLocalizedText(item, 'link_name'),
        show_more: item.show_more
      }));
    }
  }

  _listBriefcases() {
    UI.loading('show');
    this.briefcase_service.listBriefcases()
    .then((r) => {
      if(r.success == true) {
        this.context.list_briefcases = [];
        if(r.data && r.data.length > 0) {
          r.data.forEach((v) => {
            if(v.state == 1) {
              const description = this.getLocalizedText(v, 'description');
              this.context.list_briefcases.push({
                ...v,
                localizedTitle: this.getLocalizedText(v, 'title'),
                localizedDescription: description,
                localizedLinkName: this.getLocalizedText(v, 'link_name'),
                show_more: description.length > 158 ? true : false,
              });
            }
          });
        }
        UI.loading('hide');
      }
    }).catch((e) => {
      UI.loading('hide');
      UI.mensajeBad('', e.msg);
    });
  }

  toggleText(position) {
    this.context.list_briefcases[position].show_more = !this.context.list_briefcases[position].show_more;
  }
}

export default BriefcaseController;