import Vue from 'vue';
import VueRouter from 'vue-router';
import App from '../src/core/layouts/App.vue';
import Vuetify from 'vuetify';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vuetify/dist/vuetify.min.css';
import System from './core/framework/System';
import {MODULES} from './modules/_core/declarations';
import Enviroment from './core/helpers/Enviroment';
import HighchartsVue from 'highcharts-vue';
import VueQrcodeReader from "vue-qrcode-reader";
import axios from 'axios';

console.log('Iniciando configuración de la aplicación');

// Configuración de axios
axios.defaults.baseURL = 'https://soy.esenttia.co/api-bridge.php';
axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Accept'] = 'application/json';

axios.interceptors.response.use(
  response => response,
  error => {
    console.error('Axios error:', error);
    return Promise.reject(error);
  }
);

Vue.prototype.$http = axios;

Vue.use(VueQrcodeReader);

if(Enviroment.getDisableCss() != "true") {
  console.log('Cargando CSS por defecto');
  require('./css-default');
}

window.ENVIROMENT = {};
window.routes = [];
window.menu = [];

console.log('Cargando módulos base');
require('./core/base/top');
require('./core/route/module');
require('./modules/_core/main_top');

console.log('Cargando módulos adicionales');
MODULES.forEach(module => {
  try {
    console.log(`Cargando módulo: ${module}`);
    require(`./modules/${module}/module`);
  } catch(e) {
    console.error(`Error loading module ${module}:`, e);
  }
});

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(Vuetify);
Vue.use(VueSweetalert2);
Vue.use(HighchartsVue);

console.log('Configurando Vue Router');
const router = new VueRouter({
  mode: 'hash',
  routes: window.routes
});

const SystemObj = new System();

console.log('Cargando módulos finales');
require('./modules/_core/main_bottom');
require('./core/base/bottom');

console.log('Iniciando aplicación Vue');

// Manejador global de errores
Vue.config.errorHandler = function (err, vm, info) {
  console.error('Error en la aplicación Vue:', err, info);
};

new Vue({
  el: '#app',
  router,
  vuetify: new Vuetify({
    theme: {
      themes: {
        light: {
          primary: '#321fdb',
          secondary: '#ced2d8',
          success: '#2eb85c',
          warning: '#f9b115',
          danger: '#e55353',
          info: '#39f',
          light: '#ebedef',
          dark: '#636f83',
        },
        dark: {
          primary: '#4638c2',
          secondary: '#4c4f54',
          success: '#45a164',
          warning: '#e1a82d',
          danger: '#d16767',
          info: '#4799eb',
          light: '#20202a',
          dark: '#181924',
        },
      },
    },
    breakpoint: {
      thresholds: {
        xs: 320,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200
      },
      scrollBarWidth: 24,
    },
  }),
  watch:{
    $route (to, from){
      console.log(`Ruta cambiada de ${from.path} a ${to.path}`);
      dispatchEvent(new CustomEvent('changeRoute', {
        detail: { to, from }
      }));
    }
  },
  mounted(){
    console.log('Aplicación Vue montada');
    document.title = process.env.VUE_APP_TITLE;
    SystemObj.setTokens();
    SystemObj.generateVisitId();
  },
  render: h => h(App)
}).$mount('#app');

console.log('Aplicación Vue inicializada');