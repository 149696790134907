<template>
  <div>
    <v-bottom-navigation background-color="custom-warning" color="white" v-model="header" height="auto" max-height="inherit" class="custom-navigation" grow>
      <v-btn value="profile" min-height="inherit" height="auto" class="my-2" :href="'#/web/profile/'+contact_id">
        <span class="fw-700 fz-14 mt-1 text-white">{{ translate('contact') }}</span>
        <v-icon class="fz-34 text-white">mdi-card-account-phone-outline</v-icon>
      </v-btn>

      <v-btn value="company" min-height="inherit" height="auto" class="my-2" :href="'#/web/company/?id='+contact_id">
        <span class="fw-700 fz-14 mt-1 text-white">{{ translate('company') }}</span>
        <img src="../../_core/images/iconos.png" clasS="icono-family2" alt="">
      </v-btn>

      <v-btn value="contact" min-height="inherit" height="auto" class="my-2" :href="'#/web/briefcase/?id='+contact_id">
        <span class="fw-700 fz-14 mt-1 text-white">{{ translate('portfolio') }}</span>
        <v-icon class="fz-34 text-white">mdi-briefcase-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <v-btn id="language-toggle" class="language-toggle"
           style="height: 36px; width: 60px;"
           @click="toggleLanguage">
      <span class="fw-bold fz-14 text-white">{{ buttonText }}</span>
      <v-icon class="fz-30 text-white">mdi-translate</v-icon>
    </v-btn>
    
    <section class="px-3 pb-3 pt-4 mt-5 section-public min-vh-100">
      <img width="100%" class="bg-company" />

      <div class="row justify-content-center pt-3 pt-md-4 position-relative pb-5 pb-sm-0 pb-lg-5">
        <div class="col-sm-12 col-md-10 col-lg-9">
          <template v-for="(item, i) in list_briefcases">
            <div class="row justify-content-center justify-content-sm-around align-items-center flex-column" :key="i">
              <div class="col-sm-12 col-lg-12">
                <h3 class="custom-danger--text fz-24 mt-0 fw-900 mb-0 text-center" v-if="item.localizedTitle">
                  {{ item.localizedTitle }}
                </h3>
              </div>
              <div class="col-sm-12 col-lg-12">
              </div>

              <div class="col-12 col-lg-12 mt-3" v-if="item.image">
                <img :src="File.fileUrlbrief(item.image)" class="d-block mx-auto" width="90" :alt="translate('portfolioImageAlt')">
              </div>

              <div class="col-12 col-lg-12 mt-4 mt-sm-0 mt-lg-4">
                <div :class="getLocalizedDescription(item) ? '' : 'invisible'">
                  <div :class="item.show_more ? 'text-center mb-0 fw-500 text-dark-standar fz-16 text-show-more' : 'text-center mb-0 fw-500 text-dark-standar fz-16'" ref="text_more">
                    {{ getLocalizedDescription(item) }}
                  </div>
                </div>

               <!-- Botones de "Leer más/menos" actualizados para usar la descripción localizada -->
              <v-btn 
              @click="toggleText(i)" 
              v-if="item.show_more && item.localizedDescription.length > 158" 
              color="custom-danger" 
              text 
              class="d-table mx-auto"
            >
              {{ translate('readMore') }} +
            </v-btn>
            <v-btn 
              @click="toggleText(i)" 
              v-if="!item.show_more && item.localizedDescription.length > 158" 
              color="custom-danger" 
              text 
              class="d-table mx-auto"
            >
              {{ translate('readLess') }} -
            </v-btn>

            <!-- Botón con texto localizado -->
            <div class="d-flex align-items-center justify-content-center mx-auto mt-2" v-if="item.link && item.localizedLinkName">
              <v-btn 
                :href="item.link" 
                color="custom-danger" 
                class="d-flex align-items-center justify-content-center text-center" 
                target="_blank"
              >
                {{ item.localizedLinkName }}
              </v-btn>
            </div>
              </div>
            </div>

            <hr class="line-danger my-5" :key="'separator-'+i">
          </template>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Helpers from '../../../core/helpers/Helpers';
import File from "../../../core/helpers/File";
import BriefcaseController from '../controllers/BriefcaseController';
import TranslationService from '@/services/translationService';

export default {
  name: 'BriefcaseView',
  data() {
    return {
      Helpers,
      File,
      header: 'briefcase',
      list_briefcases: [],
      contact_id: null,
      briefcase_controller: null,
      positionTranslations: {
        es: '',
        en: ''
      },
    };
  },
  computed: {
    translate() {
      return TranslationService.t;
    },
    buttonText() {
      return this.currentLanguage === 'en' ? 'ES' : 'EN';
    }
  },

  mounted() {
    this.briefcase_controller = new BriefcaseController(this);

    // Escuchar cambios de idioma
    TranslationService.EventBus.$on('localeChanged', () => {
      console.log('Cambio de idioma detectado en BriefcaseView');
      this.$forceUpdate();
    });
  },
  methods: {
    toggleText(position) {
      this.briefcase_controller.toggleText(position);
    },
    checkTranslation() {
    console.log('readMore translation:', this.translate('readMore'));
    console.log('readLess translation:', this.translate('readLess'));
  },
    getLocalizedDescription(item) {
    const currentLocale = TranslationService.getCurrentLocale();

    if (currentLocale === 'es') {
      return item.description || 'Sin descripción disponible'; // Descripción en español
    } else {
      return item.description_english || 'No description available'; // Descripción en inglés
    }
  },
  toggleLanguage() {
      this.currentLanguage = this.currentLanguage === 'es' ? 'en' : 'es';
      TranslationService.setLocale(this.currentLanguage);
      
      // Actualizar el cargo en el template
      if (this.profile_controller) {
        const position = this.positionTranslations[this.currentLanguage];
        const positionElement = this.$el.querySelector('[data-position-translation]');
        if (positionElement) {
          positionElement.textContent = position;
        }
      }

      this.$emit('languageChanged', this.currentLanguage);
    }
  },
};
</script>

<style>
/* Ajuste del botón flotante */
#language-toggle {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1050; /* Para que esté sobre otros elementos */
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #ff8201; /* Color de fondo Bootstrap */
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}
#language-toggle:hover {
  background-color: #0056b3; /* Cambio de color al pasar el mouse */
}
.theme--light.v-btn.v-btn--has-bg {
  background-color: #ff8201;
}
</style>