import Rest from '../../../core/libs/Rest';

class ManageRepository {
  rest;

  constructor() {
    this.rest = new Rest();
  }
  createBriefcase(title, title_english, image, description, description_english, link, link_name, link_name_english) {
    // Mostrar en consola los datos que se están enviando
    console.log('Datos enviados al backend:', {
      title,
      title_english,
      image,
      description,
      description_english,
      link,
      link_name,
      link_name_english
    });
  
    // Configurar los parámetros
    this.rest.setParams({
      title: title,
      title_english: title_english,
      image: image,
      description: description,
      description_english: description_english,
      link: link,
      link_name: link_name,
      link_name_english: link_name_english
    });
  
    // Establecer el endpoint y realizar la solicitud POST
    this.rest.setEndPoint('api-bridge.php/card/briefcase/store-briefcase');
    return this.rest.post();
  }

  updateBriefcase(briefcase_id, title, title_english, image, description, description_english, link, link_name, link_name_english) {
    console.log('Datos enviados al backend:', {
      title,
      title_english,
      image,
      description,
      description_english,
      link,
      link_name,
      link_name_english
    });
    
    this.rest.setParams({
      title: title,
      title_english: title_english,
      image: image,
      description: description,
      description_english: description_english,
      link: link,
      link_name: link_name,
      link_name_english: link_name_english
    });
    
    this.rest.setEndPoint('/api-bridge.php/card/briefcase/update-briefcase');
    return this.rest.put(briefcase_id);
  }

  infoBriefcase(briefcase_id) {
    this.rest.setParams('/'+briefcase_id);
    this.rest.setEndPoint('/api-bridge.php/card/briefcase/show-briefcase');
    return this.rest.get();
  }

  toggleStateBriefcase(briefcase_id) {
    this.rest.setParams('');
    this.rest.setEndPoint('/api-bridge.php/card/briefcase/toogle-state-briefcase');
    return this.rest.put(briefcase_id);
  }
}

export default ManageRepository;