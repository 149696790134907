import UI from  '../../../core/helpers/UI';
// import File from "../../../core/helpers/File";
import PositionsUsersService from '../services/PositionsUsersService';
import TranslationService from "@/services/translationService";

class PositionsUsersController {
  context;
  positions_users_service;
  valid;

  constructor(context) {
    this.context = context;
    this.positions_users_service = new PositionsUsersService();

    // Configuración inicial de headers según el idioma actual
    this.updateHeadersAndValues(TranslationService.getCurrentLocale());

    this._listPositionsUsers();

    // Escuchar cambios de idioma
    TranslationService.EventBus.$on("localeChanged", (newLocale) => {
      console.log('Evento localeChanged recibido en el controlador:', newLocale);
      this.updateHeadersAndValues(newLocale);
    });
  }

  updateHeadersAndValues(locale) {
    const isEnglish = locale === 'en';
    
    // Actualizar headers
    this.context.headers = [
      { text: isEnglish ? 'Name' : 'Nombre', value: isEnglish ? 'display_name' : 'display_name' },
      { text: isEnglish ? 'Description' : 'Descripción', value: isEnglish ? 'display_description' : 'display_description' },
      { text: isEnglish ? 'Creation Date' : 'Fecha creación', value: 'date' },
      { text: '', value: 'actions' },
    ];

    // Actualizar valores en la lista
    if (this.context.lists && this.context.lists.length > 0) {
      this.context.lists = this.context.lists.map(item => ({
        ...item,
        display_name: isEnglish ? item.name_en || item.name : item.name,
        display_description: isEnglish ? item.description_en || item.description : item.description
      }));
    }
  }

  _listPositionsUsers() {
    this.context.lists = [];
    UI.loading('show');
    this.positions_users_service.listPositionsUsers()
    .then((r) => {
      if(r.success == true) {
        let list = [];
        if(r.data.items && r.data.items.length > 0) {
          const isEnglish = TranslationService.getCurrentLocale() === 'en';
          
          r.data.items.forEach((v) => {
            list.push({
              cuenta_id: v.cuenta_id,
              date: v.date,
              name: v.name,
              description: v.description != null && v.description != 'null' ? v.description : '',
              name_en: v.name_en != null && v.name_en != 'null' ? v.name_en : v.name,
              description_en: v.description_en != null && v.description_en != 'null' ? v.description_en : v.description,
              display_name: isEnglish ? (v.name_en || v.name) : v.name,
              display_description: isEnglish ? (v.description_en || v.description) : v.description,
              position_ente_id: v.position_ente_id,
              position_id: v.position_id,
              state: v.state
            });
          });
          this.context.lists = list;
        }
        UI.loading('hide');
      }
    }).catch((e) => {
      UI.loading('hide');
      UI.mensajeBad('', e.msg);
    });
  }

  
  
 deletePositionUser(position_id) {
    UI.loading('show');
    this.positions_users_service.deletePositionUser(position_id)
    .then((r) => {
      if(r.success == true) {
        UI.mensajeOk('', r.msg);
        this._listPositionsUsers();
        UI.loading('hide');
      }
    }).catch((e) => {
      UI.loading('hide');
      UI.mensajeBad('', e.msg);
    });
  }

  openDialog() {
    this.context.$set(this.context.dialog_manage, 0, true);
  }

  closeDialog() {
    this.context.$set(this.context.dialog_manage, 0, false);
    this.context.$refs.form_manage.reset();
    this.context.position_id = null;
    this.context.is_edit = false;
    // Limpiar campos en inglés
    this.context.name_en = '';
    this.context.description_en = '';
  }

  editPositionUser(info) {
    this.openDialog();
    this.context.is_edit = true;
    this.context.position_id = info.position_id;
    this.context.name = info.name;
    this.context.description = info.description;
    // Establecer campos en inglés
    this.context.name_en = info.name_en;
    this.context.description_en = info.description_en;
  }

  managePositionUser() {
    if(this.context.is_edit) {
      this._updatePositionUser();
    } else {
      this._createPositionUser();
    }
  }

  _createPositionUser() {
    if(this.context.$refs.form_manage.validate()) {
      UI.loading('show');
      this.positions_users_service.createPositionUser(
        this.context.name, 
        this.context.description,
        this.context.name_en,
        this.context.description_en
      )
      .then((r) => {
        if(r.success == true) {
          UI.mensajeOk('', r.msg);
          this.closeDialog();
          this._listPositionsUsers();
          UI.loading('hide');
        }
      }).catch((e) => {
        UI.loading('hide');
        UI.mensajeBad('', e.msg);
      });
    }
  }

  _updatePositionUser() {
    if(this.context.$refs.form_manage.validate()) {
      UI.loading('show');
      this.positions_users_service.updatePositionUser(
        this.context.position_id, 
        this.context.name, 
        this.context.description,
        this.context.name_en,
        this.context.description_en
      )
      .then((r) => {
        if(r.success == true) {
          UI.mensajeOk('', r.msg);
          this.closeDialog();
          this._listPositionsUsers();
          UI.loading('hide');
        }
      }).catch((e) => {
        UI.loading('hide');
        UI.mensajeBad('', e.msg);
      });
    }
  }
}

export default PositionsUsersController;