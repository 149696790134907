<template>
  <div v-resize="onResize">
    <div>
      <v-expansion-panels accordion>
        <v-expansion-panel v-for="item in menu" :key="item.informacion.label">
          <v-expansion-panel-header>
            <span><i :class="item.informacion.icon ? item.informacion.icon : 'mdi mdi-view-module'"></i></span>
            {{ translate(item.informacion.label) }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-expansion-panels accordion v-model="accordion">
              <v-expansion-panel v-for="(item_hijo, index) in item.hijos" :key="index">
                <v-expansion-panel-header>
                  <span><i :class="item_hijo.informacion.icon ? item_hijo.informacion.icon : 'mdi mdi-format-list-bulleted-square'"></i></span>
                  {{ translate(item_hijo.informacion.label) }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ul class="sub-menu list-unstyled">
                    <li v-for="(info_hijo, index) in item_hijo.hijos" :key="index" @click="accordion = null">
                      <a :href="info_hijo.informacion.link">{{ translate(info_hijo.informacion.label) }}</a>
                    </li>
                  </ul>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <div class="bar-actions-nav px-3 options-menu" v-if="windowSize >= 992">
        <!-- Reemplazamos el enlace por un botón que llama a la función logout -->
        <button @click="logout" class="btn shadow-none" v-if="estate_menu">
          <i class="fas fa-sign-out-alt fa-rotate-180"></i>
        </button>
        <a href="" class="btn shadow-none" v-if="estate_menu">
          <i class="fas fa-cog"></i>
        </a>
        <button type="button" class="btn shadow-none d-none d-lg-block" @click="hideMenu()">
          <i :class="!estate_menu ? 'fas fa-chevron-right' : 'fas fa-chevron-left'"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { EventSystem } from "../../../libs/Events";
import Helpers from "../../../helpers/Helpers";
import MenuController from "../controllers/MenuController";
import Session from "../../../helpers/Session";
import TranslationService from "@/services/translationService";

export default {
  name: "MainMenu",
  data() {
    return {
      Helpers,
      menu: [],
      estate_menu: false,
      accordion: null,
      windowSize: {
        x: 0,
        y: 0,
      },
    };
  },
  computed: {
    translatedMenu() {
      const translateMenu = (menu) => {
        return menu.map((item) => {
          console.log("Traduciendo menú principal:", item.informacion.label);
          const translatedLabel = this.translate(item.informacion.label);

          console.log(`Traducido: ${item.informacion.label} -> ${translatedLabel}`);

          const translatedHijos = item.hijos
            ? item.hijos.map((hijo) => {
                console.log("Traduciendo submenú:", hijo.informacion.label);
                const translatedSubLabel = this.translate(hijo.informacion.label);
                console.log(`Submenú traducido: ${hijo.informacion.label} -> ${translatedSubLabel}`);

                return {
                  ...hijo,
                  informacion: {
                    ...hijo.informacion,
                    label: translatedSubLabel,
                  },
                };
              })
            : [];

          return {
            ...item,
            informacion: {
              ...item.informacion,
              label: translatedLabel,
            },
            hijos: translatedHijos,
          };
        });
      };

      const result = translateMenu(this.menu);
      console.log("Menú completamente traducido:", result);
      return result;
    },
  },
  mounted() {
    new MenuController(this);

    EventSystem.on("toggle-menu", () => {
      this.hidde_menu_class = !this.hidde_menu_class;
    });

    TranslationService.EventBus.$on("localeChanged", () => {
      console.log("Cambio de idioma detectado en Menu.vue");
      this.$forceUpdate();
    });

    this.onResize();
  },
  methods: {
    // Método para cerrar sesión
    logout() {
      // Primero hacer una llamada al endpoint existente de cierre de sesión
      const xhr = new XMLHttpRequest();
      xhr.open('GET', '/api-bridge.php/crcore/cerrarsession', true);
      xhr.onreadystatechange = function() {
        if (xhr.readyState === 4) {
          // Cuando la petición termina, independientemente de la respuesta
          console.log("Sesión cerrada en el servidor");
          
          // Limpiar datos locales
          localStorage.clear();
          sessionStorage.clear();
          
          // Utilizar el Session helper para limpiar la sesión
          if (Session && typeof Session.clear === 'function') {
            Session.clear();
          } else if (Session && typeof Session.remove === 'function') {
            // Eliminar tokens específicos si Session.clear no existe
            Session.remove('token');
            Session.remove('user');
            Session.remove('menu_opened');
            // Añadir cualquier otra clave de sesión que debas limpiar
          }
          
          // Forzar redirección con reemplazo de historial
          window.location.replace(Helpers.urlInterna('login/ingresar'));
        }
      };
      xhr.send();
    },
    translate(key) {
      try {
        const translation = TranslationService.t(key);
        return translation;
      } catch (error) {
        console.error(`Error al traducir la clave: ${key}`, error);
        return key;
      }
    },
    hideMenu() {
      EventSystem.emit("toggle-menu", {});

      let menu_state = Session.get("menu_opened");
      if (menu_state === "TRUE") {
        this.estate_menu = true;
        Session.set("menu_opened", "FALSE");
      } else {
        this.estate_menu = false;
        Session.set("menu_opened", "TRUE");
      }
    },
    onResize() {
      this.windowSize = window.innerWidth;

      if (this.windowSize < 991) {
        this.hidde_menu_class = false;
      } else {
        this.hidde_menu_class = true;
      }
    },
  },
};
</script>

<style>
.language-toggle {
  margin-bottom: 20px;
}
</style>