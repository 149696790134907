import Rest from '../../../core/libs/Rest';
// import { LIST_MOCKUP } from '../mockups/List';

class ListRepository {
  rest;

  constructor() {
    this.rest = new Rest();
  }

  listTemplates() {
    this.rest.setParams('');
    this.rest.setEndPoint('/api-bridge.php/card/template/paginate-all');
    return this.rest.get();
  }

  deleteTemplate(template_id) {
    this.rest.setParams('');
    this.rest.setEndPoint('/api-bridge.php/card/template/delete-template');
    return this.rest.put(template_id);
  }
}

export default ListRepository;